import { TableCell, TableRow, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { Loading } from 'react-admin'
import {
  useRedirect,
  required,
  Create,
  SimpleForm,
  SaveButton,
  TextInput,
  ReferenceInput,
  RadioButtonGroupInput,
  SelectInput,
  DateInput,
} from 'react-admin'

import { apiProvider } from '../../../../provider/apiProvider'
import { Application } from '../../../../provider/types/application'
import {
  DesireCallDefaultValue,
  GenderDefaultValue,
  AgeGroupDefaultValue,
} from '../../../../provider/types/application'
import {
  ResearchDefaultValue,
  ImportantPointDefaultValue,
} from '../../../../provider/types/hearing'
import { PrefectureDefaultValue } from '../../../../provider/types/prefecture'
import { ContentsTitle } from '../../../atom/ContentsTitle'
import { MuiButton } from '../../../atom/MuiButton'
import { MuiTopToolbar } from '../../../atom/MuiTopToolbar'
import { ConfirmModal } from '../../../organism/ConfirmModal'
import { MuiBreadCrumbs } from '../../../organism/MuiBreadCrumbs'
import { MuiTable } from '../../../organism/MuiTable'

import styles from './style.module.css'

export const ApplicationCreate = () => {
  const [loading, setLoading] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false)

  const [prefectureChoices, setPrefectureChoices] = useState(
    PrefectureDefaultValue,
  )
  const [investigationChoices, setInvestigationChoices] =
    useState(ResearchDefaultValue)
  const [ageGroupChoices, setAgeGroupChoices] = useState(AgeGroupDefaultValue)
  const [importantPointChoices, setImportantPointChoices] = useState(
    ImportantPointDefaultValue,
  )
  const [genderChoices, setGenderChoices] = useState(GenderDefaultValue)
  const [callTimesChoices, setCallTimesChoices] = useState(
    DesireCallDefaultValue,
  )
  const [modalActiveFlg, setModalActiveFlg] = useState(false)

  const redirect = useRedirect()

  const fullWidthNumberChange = (target: string) => {
    target.replace(/[\uFF10-\uFF19]/g, (number) => {
      return String.fromCharCode(number.charCodeAt(0) - 0xfee0)
    })
  }

  /**
   * 電話番号のバリデーション関数
   * @params {string} value 値
   */
  const validatePhoneNumber = (value: string) => {
    const pattern = /^[0-9]{10,11}$/ // 10桁の電話番号を想定
    const phoneInput = value.replace(/[- ]/g, '')
    fullWidthNumberChange(phoneInput)
    return pattern.test(phoneInput)
      ? undefined
      : '電話番号の記入に誤りがあります。'
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = [
          apiProvider.getChoicesList('prefecture'),
          apiProvider.getChoicesList('applications/agegroup'),
          apiProvider.getChoicesList('applications/gender'),
          apiProvider.getChoicesList('applications/calltimes'),
          apiProvider.getChoicesList('hearings/research'),
          apiProvider.getChoicesList('hearings/important_points'),
        ]

        const [
          prefectureData,
          ageGroupData,
          genderData,
          callTimesData,
          investigationData,
          importantPointData,
        ] = await Promise.all(promises)

        setPrefectureChoices(prefectureData)
        setAgeGroupChoices(ageGroupData)
        setGenderChoices(genderData)
        setCallTimesChoices(callTimesData)
        setInvestigationChoices(investigationData)
        setImportantPointChoices(importantPointData)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  if (loading) {
    return <Loading />
  }

  const handleFormSubmit = (value: Partial<Application>) => {
    setIsSubmit(true)

    if (isSubmit) return

    const createApplicationsPromise = apiProvider.create('applications', {
      data: value,
    })

    Promise.all([createApplicationsPromise])
      .then(() => {
        setIsSubmit(false)
        redirect(`/admin/applications/`)
      })
      .catch((errors) => {
        console.log(errors)
        setIsSubmit(false)
      })
  }

  const changeModalActiveFlg = () => {
    setModalActiveFlg(!modalActiveFlg)
  }

  function handleModalApply() {
    redirect(`/admin/applications`)
  }

  function handleModalCancel() {
    setModalActiveFlg(false)
  }

  return (
    <>
      <Create className={styles.create} actions={false}>
        <SimpleForm
          className="form"
          warnWhenUnsavedChanges
          sanitizeEmptyValues
          onSubmit={handleFormSubmit}
          toolbar={false}
        >
          <MuiTopToolbar>
            <MuiBreadCrumbs history={['申し込み一覧', `新規申し込み`]} />
            <Stack direction="row" spacing={2.5} alignItems="center">
              <MuiButton
                text="キャンセル"
                color="reverse"
                onClick={() => setModalActiveFlg(true)}
              />
              <SaveButton
                className={`${styles.button} ${styles.save}`}
                variant="contained"
                label="新規登録"
                alwaysEnable
                icon={<></>}
              />
            </Stack>
          </MuiTopToolbar>
          <ContentsTitle title="新規申し込み" />
          <MuiTable>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>氏名</TableCell>
              <TableCell className={`${styles.tableRowData}`}>
                <TextInput
                  label="氏名"
                  source="customer.name"
                  variant="outlined"
                  helperText={false}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                    }
                  }}
                  validate={required()}
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>ふりがな</TableCell>
              <TableCell className={`${styles.tableRowData}`}>
                <TextInput
                  label="ふりがな"
                  source="customer.kana"
                  variant="outlined"
                  helperText={false}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>性別</TableCell>
              <TableCell className={styles.tableRowData}>
                <ReferenceInput
                  source="gender.id"
                  reference="applications/gender"
                >
                  <RadioButtonGroupInput
                    optionValue="id"
                    optionText="name"
                    label=""
                    helperText={false}
                    defaultValue={genderChoices[0].id}
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>年代</TableCell>
              <TableCell className={styles.tableRowData}>
                <ReferenceInput
                  source="age_group.id"
                  reference="applications/agegroup"
                >
                  <SelectInput
                    variant="outlined"
                    className={styles.selectBox}
                    formClassName={styles.selectInput}
                    label="年代"
                    helperText={false}
                    optionText="name"
                    optionValue="id"
                    defaultValue={ageGroupChoices[0].id}
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>都道府県</TableCell>
              <TableCell className={styles.tableRowData}>
                <ReferenceInput source="prefecture.id" reference="prefecture">
                  <SelectInput
                    variant="outlined"
                    className={styles.selectBox}
                    formClassName={styles.selectInput}
                    label="都道府県"
                    helperText={false}
                    optionText="name"
                    optionValue="id"
                    defaultValue={prefectureChoices[0].id}
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>
                メールアドレス
              </TableCell>
              <TableCell className={styles.tableRowData}>
                <TextInput
                  fullWidth
                  label="メールアドレス"
                  source="email"
                  variant="outlined"
                  helperText={false}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                    }
                  }}
                  sx={{ width: '50%' }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>電話番号</TableCell>
              <TableCell className={styles.tableRowData}>
                <TextInput
                  label="電話番号"
                  source="phone_number"
                  variant="outlined"
                  helperText={false}
                  validate={[required(), validatePhoneNumber]}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>調査内容</TableCell>
              <TableCell className={styles.tableRowData}>
                <ReferenceInput
                  source="hearing.research_type.id"
                  reference="hearings/research"
                >
                  <SelectInput
                    variant="outlined"
                    label="調査内容"
                    optionText="name"
                    optionValue="id"
                    helperText={false}
                    defaultValue={investigationChoices[0].id}
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>
                一番気になっている事
              </TableCell>
              <TableCell className={styles.tableRowData}>
                <ReferenceInput
                  source="hearing.important_point.id"
                  reference="hearings/important_points"
                >
                  <SelectInput
                    variant="outlined"
                    label="一番気になっている事"
                    optionText="name"
                    optionValue="id"
                    helperText={false}
                    defaultValue={importantPointChoices[0].id}
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>
                電話希望日時
              </TableCell>
              <TableCell
                className={`${styles.tableRowData} ${styles.targetFlex}`}
              >
                <DateInput
                  label="電話希望日"
                  source="desired_call.date"
                  variant="outlined"
                  helperText={false}
                  validate={required()}
                />
                <ReferenceInput
                  source="desired_call.id"
                  reference="applications/calltimes"
                >
                  <SelectInput
                    label="電話希望時間"
                    variant="outlined"
                    optionText="range_time"
                    optionValue="id"
                    helperText={false}
                    defaultValue={callTimesChoices[0].id}
                    validate={required()}
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>特記事項</TableCell>
              <TableCell className={styles.tableRowData}>
                <TextInput
                  variant="outlined"
                  className={`${styles.note} ${styles.shared_note}`}
                  multiline
                  fullWidth
                  placeholder="特記事項があれば記入してください"
                  label={false}
                  helperText={false}
                  source="note"
                  inputProps={{ maxLength: 1000 }}
                />
              </TableCell>
            </TableRow>
          </MuiTable>
          <Stack gap={2.5} direction="row" className={styles.container}>
            <MuiButton
              text="キャンセル"
              color="reverse"
              onClick={() => setModalActiveFlg(true)}
            />
            <SaveButton
              className={`${styles.button} ${styles.save}`}
              variant="contained"
              label="新規登録"
              alwaysEnable
              icon={<></>}
            />
          </Stack>
        </SimpleForm>
      </Create>
      {modalActiveFlg && (
        <ConfirmModal
          open={modalActiveFlg}
          setOpen={changeModalActiveFlg}
          title="新規申込を破棄しますか？"
          applyText="破棄"
          onClickApply={handleModalApply}
          cancelText="キャンセル"
          onClickCancel={handleModalCancel}
        />
      )}
    </>
  )
}
