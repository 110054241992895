import style from './style.module.css'

export const Spinner = () => {
  return (
    <div className={style.loaders}>
      <div className={style.loader}>
        <div
          className={`${style['loader-inner']} ${style['ball-spin-fade-loader']}`}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}
