import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'

import { apiProvider } from '../../../provider/apiProvider'
import { SendPassword } from '../../../provider/types/user'
import { MuiButton } from '../../atom/MuiButton'
import { MuiModal } from '../MuiModal'

import './global.css'
import styles from './style.module.css'
import { TypeSetShowPassword, TypeShowPassword } from './type'

export const PasswordChangeModal = ({
  modalActiveFlg,
  changeModalActiveFlg,
  dataSendSuccessFlg,
  setDataSendSuccessFlg,
  changePasswordState,
  updateChangePassword,
}: {
  modalActiveFlg: boolean
  dataSendSuccessFlg: boolean
  changePasswordState: SendPassword
  setDataSendSuccessFlg: React.Dispatch<React.SetStateAction<boolean>>
  changeModalActiveFlg: () => void
  updateChangePassword: (key: string, value: string) => void
}) => {
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewConfirmationPassword, setShowConfirmationNewPassword] =
    useState(false)
  const [currentPWError, setCurrentPWError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [passwordError, setPasswordError] = useState(false)

  /**
   * パスワード変更処理
   * @returns void
   */
  const sendFunc = () => {
    apiProvider
      .update('profile', {
        id: '',
        data: changePasswordState,
        previousData: undefined,
      })
      .then(() => {
        setPasswordError(false)
        setCurrentPWError(false)
        setDataSendSuccessFlg(true)
      })
      .catch((err) => {
        if (
          err.response.data.status !== 401 &&
          'current_password' in err.response.data.errors
        ) {
          setCurrentPWError(true)
          setErrorMessage('パスワードは必須入力です')
        } else if (err.response.data.status === 401) {
          setCurrentPWError(true)
          setErrorMessage('パスワードが間違えています')
        } else if (
          err.response.data.errors.length > 0 &&
          'current_password' in err.response.data.errors === false
        ) {
          setCurrentPWError(false)
        }
        setDataSendSuccessFlg(false)
      })
  }

  /**
   * パスワード表示処理
   * @returns void
   */
  const togglePasswordVisibility = (
    setShowPassword: TypeSetShowPassword,
    showPassword: TypeShowPassword,
  ) => {
    setShowPassword(!showPassword)
  }

  /**
   * 新しいパスワード比較処理
   * @returns void
   */
  const checkPassword = () => {
    const currentPassJudge = changePasswordState.current_password
    const newPassJudge = changePasswordState.new_password
    const confPassJudge = changePasswordState.new_password_confirmation
    // flag =  true
    const currentPWCondition =
      currentPassJudge !== '' && currentPassJudge.length >= 8
    const passwordComparison = newPassJudge !== '' && confPassJudge !== ''

    if (!currentPWCondition) {
      setCurrentPWError(true)
      setErrorMessage('パスワードは必須入力です')
    }

    if (!passwordComparison) {
      setPasswordError(true)
      setErrorMessage('パスワードは必須入力です')
      sendFunc()
      return
    }

    const errorCondition = newPassJudge === confPassJudge
    if (!errorCondition) {
      setPasswordError(true)
      setErrorMessage('新しいパスワードが間違っています')
      sendFunc()
      return
    }

    const lengthCondition = newPassJudge.length >= 8
    if (!lengthCondition) {
      setPasswordError(true)
      setErrorMessage('パスワードは8文字以上で入力してください')
      sendFunc()
      return
    }

    const useAlphabetNumberCondition =
      /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/.test(newPassJudge)
    if (!useAlphabetNumberCondition) {
      setPasswordError(true)
      setErrorMessage('パスワードは半角英数字を含めてください')
      sendFunc()
      return
    }

    setPasswordError(false)
    sendFunc()
  }

  return (
    <MuiModal open={modalActiveFlg} closeModal={() => changeModalActiveFlg()}>
      <Typography className={styles.modalTitle}>パスワードの変更</Typography>

      {!dataSendSuccessFlg && (
        <>
          <Typography className={styles.modalComment}>
            パスワードを入力してください。
          </Typography>
          <Box className={styles.inputBox}>
            <Typography
              className={`${styles['label']} ${
                currentPWError ? styles['error'] : ''
              }`}
            >
              現在のパスワード
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              label={'パスワード'}
              placeholder="パスワード"
              error={currentPWError}
              type={showOldPassword ? 'text' : 'password'}
              className={`${styles['loginField']} ${
                currentPWError
                  ? styles['loginFieldError']
                  : styles['loginFieldDefault']
              }`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        togglePasswordVisibility(
                          setShowOldPassword,
                          showOldPassword,
                        )
                      }}
                      edge="end"
                    >
                      <img
                        src="/images/icon/hidingPassword-icon.svg"
                        alt=""
                        className={styles.viewIcon}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                updateChangePassword('current_password', event.target.value)
              }}
            />
          </Box>
          <Box className={styles.inputBox}>
            <Typography
              className={`${styles['label']} ${
                passwordError ? styles['error'] : ''
              }`}
            >
              新しいパスワード
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              label={'パスワード'}
              placeholder="パスワード"
              type={showNewPassword ? 'text' : 'password'}
              error={passwordError}
              className={`${styles['loginField']} ${
                passwordError
                  ? styles['loginFieldError']
                  : styles['loginFieldDefault']
              }`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        togglePasswordVisibility(
                          setShowNewPassword,
                          showNewPassword,
                        )
                      }}
                      edge="end"
                    >
                      <img
                        src="/images/icon/hidingPassword-icon.svg"
                        alt=""
                        className={styles.viewIcon}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                updateChangePassword('new_password', event.target.value)
              }}
            />
            <span className={styles.annotation}>
              <span className={styles.annotationMark}>※</span>
              半角の英字と数字を含む8文字以上の文字列
            </span>
          </Box>
          <Box className={styles.inputBox}>
            <Typography
              className={`${styles['label']} ${
                passwordError ? styles['error'] : ''
              }`}
            >
              新しいパスワード（確認）
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              label={'パスワード'}
              placeholder="パスワード"
              type={showNewConfirmationPassword ? 'text' : 'password'}
              error={passwordError}
              className={`${styles['loginField']} ${
                passwordError
                  ? styles['loginFieldError']
                  : styles['loginFieldDefault']
              }`}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    className={styles['hiding-password-icon']}
                    position="end"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        togglePasswordVisibility(
                          setShowConfirmationNewPassword,
                          showNewConfirmationPassword,
                        )
                      }}
                      edge="end"
                    >
                      <img
                        src="/images/icon/hidingPassword-icon.svg"
                        alt=""
                        className={styles.viewIcon}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                updateChangePassword(
                  'new_password_confirmation',
                  event.target.value,
                )
              }}
            />
          </Box>
          {(currentPWError || passwordError) && (
            <Typography className={styles.errorMessage}>
              {errorMessage}{' '}
            </Typography>
          )}
          <Box
            className={`${styles.modalButtonContainer} ${styles.modalMargin}`}
          >
            <MuiButton
              onClick={() => changeModalActiveFlg()}
              text="キャンセル"
              color="reverse"
            />
            <MuiButton onClick={checkPassword} text="パスワード設定" />
          </Box>
        </>
      )}
      {dataSendSuccessFlg && (
        <>
          <Typography className={styles.modalComment}>
            パスワードが変更されました
          </Typography>
          <Box
            className={`${styles.modalButtonContainer} ${styles.modalMargin}`}
          >
            <MuiButton
              onClick={() => changeModalActiveFlg()}
              text="閉じる"
              color="white"
            />
          </Box>
        </>
      )}
    </MuiModal>
  )
}
