import {
  Box,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Stack,
  Typography,
  Button,
} from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useRefresh } from 'react-admin'

import { apiProvider } from '../../../../provider/apiProvider'
import { Permission } from '../../../../provider/types/user'
import {
  CreateUser,
  RegisterDefaultValue,
} from '../../../../provider/types/user'
import { MuiButton } from '../../../atom/MuiButton'
import { MuiModal } from '../../../organism/MuiModal'

import { Complete } from './Complete'
import { Loading } from './Loading'
import style from './style.module.css'
import { Props } from './type'

const baseURL = process.env.REACT_APP_PUBLIC_API_ENDPOINT

export const AccountCreateModal = ({ open, closeModal }: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [accountInfo, setAccountInfo] = useState('')
  const [user, setUser] = useState<CreateUser>(RegisterDefaultValue)
  const [choices, setChoices] = useState<Permission[]>()
  const [comment, setComment] = useState<string>('ローディング中')

  useEffect(() => {
    console.log('ehy')
    axios
      .get(`/v1/users/permission`, {
        baseURL: baseURL,
        headers: new axios.AxiosHeaders({
          'Content-Type': 'text/csv; charset=utf-8',
          Accept: 'text/csv;charset=utf-8',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
      })
      .then((res) => {
        setIsLoading(false)
        setChoices(res.data.data)
        console.log(res.data.data)
      })
  }, [])

  const [isSubmit, setIsSubmit] = useState(false)
  const [error, setError] = useState({
    name: false,
    login_id: false,
    permission: false,
  })
  const [idErrorMessage, setIDErrorMessage] = useState(
    '氏名またはログインIDを入力してください',
  )

  const refresh = useRefresh()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'permission') {
      setUser({
        ...user,
        permission: {
          id: Number(e.target.value),
          name: e.target.value == '1' ? '管理者' : 'テレオペ',
          key: e.target.value == '2' ? 'admin' : 'operator',
        },
      })
    } else {
      setUser({ ...user, [e.target.name]: e.target.value })
      if (e.target.value !== '') {
        setError({
          ...error,
          [e.target.name]: false,
        })
      } else {
        setError({
          ...error,
          [e.target.name]: true,
        })
      }
    }
    console.log(error, idErrorMessage)
  }

  const checkValidate = () => {
    Object.keys(user).forEach((key) => {
      if (key === 'permission') {
        if (
          user.permission.id === 0 ||
          user.permission.name === '' ||
          user.permission.key === ''
        ) {
          setError((prevError) => ({ ...prevError, [key]: true }))
        } else {
          setError((prevError) => ({ ...prevError, [key]: false }))
        }
      } else if (user[key as keyof typeof user] === '') {
        setError((prevError) => ({ ...prevError, [key]: true }))
      }
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setComment('アカウントを作成中です。')
    setIsSubmit(true)
    setIsLoading(true)
    checkValidate()

    if (user.name !== '' && user.login_id !== '') {
      apiProvider
        .create('users', {
          data: user,
        })
        .then((response) => {
          setAccountInfo(
            `氏名：${user.name}\nログインID：${user.login_id}\nパスワード：${response.data.password}`,
          )
          refresh()
        })
        .catch((error) => {
          const apiErrors = error.response.data.errors
          if (apiErrors['login_id'].includes('validation.unique')) {
            setError((prevError) => ({
              ...prevError,
              login_id: true,
            }))
            setIDErrorMessage('このログインIDはすでに利用されています')
          }
        })
    }
    setIsLoading(false)
  }

  return (
    <MuiModal open={open} closeModal={closeModal} title="アカウントの作成">
      {isLoading ? (
        <Loading text={comment} />
      ) : '' === accountInfo ? (
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={6.25}>
            <Box className={style.inputBox}>
              <Typography paragraph>
                1.アカウントの権限を選択してください
              </Typography>
              <RadioGroup
                row
                name="permission"
                defaultValue="2"
                onChange={handleChange}
              >
                {choices
                  ?.slice()
                  .reverse()
                  .map((item) => (
                    <FormControlLabel
                      sx={{ fontSize: '14px' }}
                      key={item.id}
                      value={item.id}
                      control={<Radio size="small" color="primary" />}
                      label={
                        item.id === 2
                          ? item.name + '（一部機能に制限あり）'
                          : item.name
                      }
                    />
                  ))}
              </RadioGroup>
            </Box>
            <Box className={style.formWrapper}>
              <Typography paragraph sx={{ margin: 0 }}>
                2.氏名とログインIDを入力してください。
              </Typography>
              <Box className={style.inputBox}>
                <Typography className={style.label}>氏名</Typography>
                <TextField
                  label="氏名"
                  type="text"
                  name="name"
                  variant="outlined"
                  fullWidth
                  error={isSubmit && error.name}
                  onChange={handleChange}
                  helperText={
                    isSubmit && error.name
                      ? '氏名またはログインIDを入力してください'
                      : false
                  }
                  InputProps={{
                    classes: {
                      error: style.loginFieldError,
                    },
                  }}
                  autoComplete="false"
                  className={`${style.loginField} ${
                    isSubmit && error.name
                      ? style['loginFieldError']
                      : style['loginFieldDefault']
                  }`}
                />
              </Box>
              <Box className={style.inputBox}>
                <Typography className={style.label}>ログインID</Typography>
                <TextField
                  label="ログインID"
                  type="text"
                  name="login_id"
                  fullWidth
                  variant="outlined"
                  error={isSubmit && error.login_id}
                  onChange={handleChange}
                  helperText={
                    isSubmit && error.login_id ? idErrorMessage : false
                  }
                  InputProps={{
                    classes: {
                      error: style.loginFieldError,
                    },
                  }}
                  autoComplete="false"
                  className={`${style.loginField} ${
                    isSubmit && error.login_id
                      ? style['loginFieldError']
                      : style['loginFieldDefault']
                  }`}
                />
              </Box>
            </Box>
          </Stack>
          <Box>
            <Grid container spacing={3} className={style.btnWrapper}>
              <Grid item xs>
                <MuiButton
                  text="キャンセル"
                  color="reverse"
                  onClick={closeModal}
                />
              </Grid>
              <Grid item xs>
                <Button type="submit" className={style.button}>
                  作成する
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Complete accountInfo={accountInfo} closeModal={closeModal} />
      )}
    </MuiModal>
  )
}
