import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ShareIcon from '@mui/icons-material/Share'
import {
  Box,
  IconButton,
  Link,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { EditButton, Loading, Show, useShowContext } from 'react-admin'

import { DateTimeUtil, DateUtil } from '../../../../Util/dateUtil'
import { FormatUtil } from '../../../../Util/formatUtil'
import { ApplicationStatusBar } from '../../../atom/ApplicationStatusBar'
import { ContentsTitle } from '../../../atom/ContentsTitle'
import { InterviewStatus } from '../../../atom/InterviewStatus'
import { MuiButton } from '../../../atom/MuiButton'
import { MuiTopToolbar } from '../../../atom/MuiTopToolbar'
import { OrderAccuracy } from '../../../atom/OrderAccuracy'
import { TopButton } from '../../../atom/TopButton'
import { CustomToolbar } from '../../../organism/CustomToolbar'
import { InterviewDataModal } from '../../../organism/InterviewDataModal'
import { MuiTable } from '../../../organism/MuiTable'

import style from './style.module.css'

const ShowRecord = () => {
  const { record, isFetching } = useShowContext()

  return isFetching ? (
    <Loading />
  ) : (
    <>
      <MuiTopToolbar>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>
            案件ID：
            <Link
              href={`/admin/applications/${record.application.id}/show`}
              color="link"
            >
              {record.application.id}
            </Link>
          </Typography>
          <Stack direction="row" alignItems="center">
            案件ステータス：
            <ApplicationStatusBar status={record.application.status} />
          </Stack>
        </Stack>
        <Typography>
          担当者：
          {record.application.staff?.name ?? '未定'}
        </Typography>
      </MuiTopToolbar>
      <ContentsTitle title="面談情報" />
      <MuiTable>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>面談ID</TableCell>
          <TableCell className={style.tableRowData}>{record.id}</TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>面談ステータス</TableCell>
          <TableCell className={style.tableRowData}>
            <InterviewStatus
              status={record.status}
              interviewDate={record.interview_date}
            />
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>面談結果</TableCell>
          <TableCell className={style.tableRowData}>
            {record.result ? record.result.name : '未選択'}
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>探偵社名</TableCell>
          <TableCell className={style.tableRowData}>
            {`${record.detective.company.name} ( ${record.detective.company.kana} )`}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(record.detective.company.name)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>
            担当者（探偵社）
          </TableCell>
          <TableCell className={style.tableRowData}>
            {`${record.detective.representative.name} ( ${record.detective.representative.kana} )`}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  record.detective.representative.name,
                )
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>
            電話番号（探偵社）
          </TableCell>
          <TableCell className={style.tableRowData}>
            {FormatUtil.formatTelNumber(record.detective.phone_number)}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(record.detective.phone_number)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>メールアドレス</TableCell>
          <TableCell className={style.tableRowData}>
            {record.detective.email}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(record.detective.email)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>場所</TableCell>
          <TableCell className={style.tableRowData}>
            {`${record.detective.prefecture.name}${record.detective.address}`}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  `${record.detective.prefecture.name}${record.detective.address}`,
                )
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>日時</TableCell>
          <TableCell className={style.tableRowData}>
            {DateTimeUtil(record.interview_date)}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  DateTimeUtil(record.interview_date),
                )
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>その他</TableCell>
          <TableCell className={`${style.tableRowData} ${style.newLine}`}>
            {record.note ? record.note : ' '}
            {record.note ? (
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(record.note ?? '')
                }}
              >
                <ContentCopyIcon className={style.icon} />
              </IconButton>
            ) : (
              '　'
            )}
          </TableCell>
        </TableRow>
      </MuiTable>
      <ContentsTitle title="ヒアリングシート" />
      <MuiTable>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>予算</TableCell>
          <TableCell className={style.tableRowData}>
            {record.application.hearing.budget
              ? `${record.application.hearing.budget}万円`
              : ' '}
            {record.application.hearing.budget && (
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    record.application.hearing.budget + '万円',
                  )
                }}
              >
                <ContentCopyIcon className={style.icon} />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>調査対象</TableCell>
          <TableCell className={style.tableRowData}>
            {record.application.hearing.target
              ? record.application.hearing.target.name
              : ''}
            {record.application.hearing.target && (
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    record.application.hearing.target.name,
                  )
                }}
              >
                <ContentCopyIcon className={style.icon} />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>
            一番気になっている事
          </TableCell>
          <TableCell className={style.tableRowData}>
            {record.application.hearing.important_point.name}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  record.application.hearing.important_point.name,
                )
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>調査内容</TableCell>
          <TableCell className={style.tableRowData}>
            {record.application.hearing.research_type
              ? record.application.hearing.research_type.name
              : '-'}
            {record.application.hearing.research_type ? (
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    record.application.hearing.research_type.name,
                  )
                }}
              >
                <ContentCopyIcon className={style.icon} />
              </IconButton>
            ) : (
              ''
            )}
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>依頼事項</TableCell>
          <TableCell className={`${style.tableRowData} ${style.newLine}`}>
            {record.application.hearing.detail
              ? record.application.hearing.detail
              : ' '}
            {record.application.hearing.detail ? (
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    record.application.hearing.detail
                      ? record.application.hearing.detail
                      : '',
                  )
                }}
              >
                <ContentCopyIcon className={style.icon} />
              </IconButton>
            ) : (
              ''
            )}
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>
            面談のご希望内容
          </TableCell>
          <TableCell className={`${style.tableRowData} ${style.newLine}`}>
            {record.application.hearing.interview_detail
              ? record.application.hearing.interview_detail
              : ' '}
            {record.application.hearing.interview_detail ? (
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    record.application.hearing.interview_detail ?? '',
                  )
                }}
              >
                <ContentCopyIcon className={style.icon} />
              </IconButton>
            ) : (
              ''
            )}
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>契約確度</TableCell>
          <TableCell className={style.tableRowData}>
            {record.application.hearing.order_accuracy ? (
              <OrderAccuracy
                status={record.application.hearing.order_accuracy}
              />
            ) : (
              ''
            )}

            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  record.application.hearing.order_accuracy
                    ? record.application.hearing.order_accuracy.name
                    : '',
                )
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
      </MuiTable>
      <ContentsTitle title="申し込み情報" />
      <MuiTable>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>申し込み日時</TableCell>
          <TableCell className={style.tableRowData}>
            {DateTimeUtil(record.application.application_date)}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  DateTimeUtil(record.application.application_date),
                )
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>依頼者名</TableCell>
          <TableCell className={style.tableRowData}>
            {`${record.application.customer.name} ( ${record.application.customer.kana} )`}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  `${record.application.customer.name} ( ${record.application.customer.kana} )`,
                )
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>性別</TableCell>
          <TableCell className={style.tableRowData}>
            {record.application.gender.name}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(record.application.gender.name)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>年代</TableCell>
          <TableCell className={style.tableRowData}>
            {`${record.application.age_group.name}`}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  record.application.age_group.name.toString(),
                )
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>都道府県</TableCell>
          <TableCell className={style.tableRowData}>
            {record.application.prefecture.name}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  record.application.prefecture.name,
                )
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>メールアドレス</TableCell>
          <TableCell className={style.tableRowData}>
            {record.application.email}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(record.application.email)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>
            電話番号（依頼者）
          </TableCell>
          <TableCell className={style.tableRowData}>
            {FormatUtil.formatTelNumber(record.application.phone_number)}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(record.application.phone_number)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>電話希望日時</TableCell>
          <TableCell className={style.tableRowData}>
            {`${DateUtil(record.application.desired_call.date)} ${
              record.application.desired_call.range_time
            }`}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  DateUtil(record.application.desired_call.date) +
                    record.application.desired_call.range_time,
                )
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
      </MuiTable>
      <Box>
        <Stack direction="row">
          <ContentsTitle title="面談メモ" />
          <Typography className={style.comment}>
            <span className={style.kome}>※</span>
            こちらの内容は共有時に含まれません。
          </Typography>
        </Stack>
        <Box className={style.note}>{record.internal_note}</Box>
      </Box>
    </>
  )
}

export const InterviewShow = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Show
      emptyWhileLoading
      className={style.show}
      actions={
        <CustomToolbar history={['面談一覧 ', '面談詳細']}>
          <Stack direction="row" spacing={2.5} alignItems="center">
            <MuiButton
              icon={<ShareIcon />}
              text="共有"
              color="reverse"
              onClick={() => setIsOpen(true)}
            />
            <EditButton className={style.button} label="修正" />
          </Stack>
        </CustomToolbar>
      }
    >
      <ShowRecord />
      <TopButton />
      {isOpen && (
        <InterviewDataModal open={isOpen} closeModal={() => setIsOpen(false)} />
      )}
    </Show>
  )
}
