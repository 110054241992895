import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  Container,
} from '@mui/material'
import { useState, ChangeEvent } from 'react'
import { useNotify } from 'react-admin'

import { authProvider } from '../../../provider/authProvider'

import styles from './style.module.css'

export const Login = () => {
  const [login_id, setLoginID] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loginIDError, setLoginIDError] = useState(false)
  const [passwordIDError, setPasswordError] = useState(false)

  const notify = useNotify()
  const [showPassword, setShowPassword] = useState(false)

  /**
   * パスワード可視化の変更処理
   * @return void
   */
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  /**
   * ログインAPI
   * @return void
   */
  const handleSubmit = () => {
    authProvider
      .login({ login_id, password })
      .then(() => {
        setLoginIDError(false)
        setPasswordError(false)
      })
      .catch(() => {
        notify('ログインIDかパスワードが間違っています。')
        setLoginIDError(true)
        setPasswordError(true)
      })
  }

  /**
   * 入力されたテキストが全角だった場合に半角に直す
   * @params {string} text 入力された値
   */
  const toHalfWidth = (text: string) => {
    return text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (textItem) => {
      return String.fromCharCode(textItem.charCodeAt(0) - 0xfee0)
    })
  }

  /**
   * テキストが入力された際に走る処理
   * @params {string} e 入力された値
   * @params {boolean} funcBoolean IDかPasswordがの判定
   */
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    funcBoolean: boolean,
  ) => {
    const inputText = e.target.value
    const halfWidthText = inputText.match(/^[A-Za-z0-9]*$/)
      ? inputText
      : toHalfWidth(inputText)
    e.target.value = inputText
    funcBoolean ? setLoginID(halfWidthText) : setPassword(halfWidthText)
  }

  /**
   * 入力後Enterが押された際に走る処理
   * @params {string} event： { key: string } 押されたkey
   */
  const handleKeyUp = (event: { key: string }) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <div className={styles.loginWrap}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Typography className={styles.hdgText} variant={'h2'}>
          探偵の窓口
        </Typography>
        <hr className={styles.line} />
        <Container className={styles.container}>
          <Typography className={styles.userText} variant={'h3'}>
            こんにちはゲストさん
          </Typography>
          <Container className={styles.loginBox} disableGutters={true}>
            <Box className={styles.inputBox}>
              <Typography
                className={`${styles['label']} ${
                  passwordIDError || loginIDError ? styles['error'] : ''
                }`}
              >
                ログインID
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                label={'ログイン'}
                placeholder="ログインIDを入力"
                error={loginIDError}
                InputProps={{
                  classes: {
                    error: styles.loginFieldError,
                  },
                }}
                className={`${styles['loginField']} ${
                  passwordIDError || loginIDError
                    ? styles['loginFieldError']
                    : styles['loginFieldDefault']
                }`}
                onChange={(event) => {
                  handleChange(event, true)
                }}
              />
            </Box>
            <Box className={styles.inputBox}>
              <Typography
                className={`${styles['label']} ${
                  passwordIDError || loginIDError ? styles['error'] : ''
                }`}
              >
                パスワード
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                label={'パスワード'}
                placeholder="パスワードを入力"
                error={passwordIDError || loginIDError}
                type={showPassword ? 'text' : 'password'}
                className={`${styles['loginField']} ${
                  passwordIDError || loginIDError
                    ? styles['loginFieldError']
                    : styles['loginFieldDefault']
                }`}
                InputProps={{
                  classes: {
                    error: styles.loginFieldError,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        <img
                          src="/images/icon/hidingPassword-icon.svg"
                          alt=""
                          className={styles.viewIcon}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autoComplete: 'new-password',
                }}
                onChange={(event) => {
                  handleChange(event, false)
                }}
                onKeyUp={handleKeyUp}
              />
            </Box>

            {passwordIDError || loginIDError ? (
              <Typography className={styles.errorMessage}>
                IDもしくはパスワードが間違っています
              </Typography>
            ) : (
              ''
            )}
          </Container>
          <Button className={styles.btn} onClick={handleSubmit} fullWidth>
            ログインする
          </Button>
          <Typography variant="body1" className={styles.questionText}>
            <img
              src="/images/icon/question-icon.svg"
              alt=""
              className={styles.questionIcon}
            />
            ID・パスワードをお忘れの場合は管理者に確認してください
          </Typography>
        </Container>
      </form>
    </div>
  )
}
