import { default as AddBoxIcon } from '@mui/icons-material/AddBox'
import {
  Button,
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import { useEffect, useState } from 'react'
import {
  DateInput,
  Edit,
  FunctionField,
  Loading,
  ReferenceInput,
  SelectInput,
  TextInput,
  useGetList,
  useGetOne,
  required,
  useRedirect,
  RadioButtonGroupInput,
  SaveButton,
  SimpleForm,
  useGetRecordId,
} from 'react-admin'

import { apiProvider } from '../../../../provider/apiProvider'
import { Application } from '../../../../provider/types/application'
import {
  DefaultValue,
  Interview,
  Errors,
  ErrorsDefault,
} from '../../../../provider/types/interview'
import { DateTimeUtil } from '../../../../Util/dateUtil'
import { ContentsTitle } from '../../../atom/ContentsTitle'
import { MuiButton } from '../../../atom/MuiButton'
import { MuiTopToolbar } from '../../../atom/MuiTopToolbar'
import { TopButton } from '../../../atom/TopButton'
import { InterviewList } from '../../../feature/Application/InterviewList'
import { ConfirmModal } from '../../../organism/ConfirmModal'
import { MuiBreadCrumbs } from '../../../organism/MuiBreadCrumbs'
import { MuiTable } from '../../../organism/MuiTable'
import { NotFound } from '../../../organism/NotFound'

import styles from './style.module.css'

export const ApplicationEdit = () => {
  const recordID = useGetRecordId()
  const redirect = useRedirect()
  const {
    data: applicationsData,
    isLoading,
    error,
  } = useGetOne('applications', { id: recordID })
  const { data: detectivesData } = useGetList('detectives')
  const [sendData, setSendData] = useState<Interview[]>([DefaultValue])
  const [isSubmit, setIsSubmit] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [errors, setErrors] = useState<Errors[]>([ErrorsDefault])
  const [modalActiveFlg, setModalActiveFlg] = useState(false)

  const fullWidthNumberChange = (target: string) => {
    target.replace(/[\uFF10-\uFF19]/g, (number) => {
      return String.fromCharCode(number.charCodeAt(0) - 0xfee0)
    })
  }

  const validatePhoneNumber = (value: string) => {
    const pattern = /^[0-9]{10,11}$/ // 10桁の電話番号を想定
    const phoneInput = value.replace(/[- ]/g, '')
    fullWidthNumberChange(phoneInput)
    return pattern.test(phoneInput)
      ? undefined
      : '電話番号の記入に誤りがあります。'
  }

  if (isLoading) {
    return <Loading />
  }

  if (error || undefined === applicationsData) {
    return <NotFound />
  }

  useEffect(() => {
    if (applicationsData) {
      updateChangeSendData(0, 'application', { id: recordID })
    }
  }, [])

  const detectivesFunc = (targetArrayNumber: number, updateNumber: number) => {
    if (detectivesData) {
      const target = detectivesData.find(
        (element) => element.id === updateNumber,
      )
      if (target === undefined) {
        updateChangeSendData(targetArrayNumber, 'location', null)
      } else {
        updateChangeSendData(targetArrayNumber, 'location', target.address)
      }
    }
  }

  const updateChangeSendData = (
    index: number | null | undefined,
    key: string,
    value: string | number | unknown,
  ) => {
    setSendData((prevArray) =>
      prevArray.map((item, prevIndex) =>
        prevIndex === index
          ? key === 'interview_date'
            ? {
                ...item,
                [key]: format(new Date(String(value)), 'yyyy-MM-dd HH:mm'),
              }
            : { ...item, [key]: value }
          : item,
      ),
    )
    if (key === 'interview_date' && value !== '') {
      setErrors((prevArray) =>
        prevArray.map((item, prevIndex) =>
          prevIndex === index ? { ...item, interview_date: false } : item,
        ),
      )
    } else if (key === 'detective' && value !== 0) {
      setErrors((prevArray) =>
        prevArray.map((item, prevIndex) =>
          prevIndex === index ? { ...item, detective_id: false } : item,
        ),
      )
    }
  }

  const addTableCell = (): void => {
    const newInterview = DefaultValue
    const newErrors = ErrorsDefault
    newInterview.application.id = applicationsData.id
    setSendData((prevState) => [...prevState, newInterview])
    setErrors((prevState) => [...prevState, newErrors])
  }

  const deleteSendInterviewData = (index: number) => {
    const newArray = [...sendData]
    const errorArray = [...errors]
    newArray.splice(index, 1)
    errorArray.splice(index, 1)
    setSendData(newArray)
    setErrors(errorArray)
    console.log(sendData)
  }

  const handleFormSubmit = (value: Partial<Application>) => {
    setIsClicked(true)

    if (isSubmit) return

    setIsSubmit(true)

    const hasError = sendData.some((item, index) => {
      if (
        item.detective.id !== 0 &&
        item.interview_date === '' &&
        setInterviewDateError(index, true)
      ) {
        return true
      } else if (
        item.detective.id === 0 &&
        item.interview_date !== '' &&
        setDetectiveIdError(index, true)
      ) {
        return true
      }
      return false
    })

    if (hasError) {
      setIsSubmit(false)
      return
    }

    handleUpdateData(value)
  }

  const setInterviewDateError = (index: number, isError: boolean) => {
    setErrors((prevArray) =>
      prevArray.map((prevItem, prevIndex) =>
        prevIndex === index
          ? { ...prevItem, interview_date: isError }
          : prevItem,
      ),
    )
    return isError
  }

  const setDetectiveIdError = (index: number, isError: boolean) => {
    setErrors((prevArray) =>
      prevArray.map((prevItem, prevIndex) =>
        prevIndex === index ? { ...prevItem, detective_id: isError } : prevItem,
      ),
    )
    return isError
  }

  const handleUpdateData = (value: Partial<Application>) => {
    const filteredSendData = sendData.filter(
      (item) => item.detective.id !== 0 || item.interview_date !== '',
    )
    setSendData(filteredSendData)

    const createInterviewsPromise = apiProvider.create('interviews', {
      data: filteredSendData,
    })
    const updateApplicationsPromise = apiProvider.update('applications', {
      id: recordID,
      data: value,
      previousData: applicationsData,
    })

    const handlePromise = () => {
      setIsSubmit(false)
      redirect(`/admin/applications/${recordID}/show`)
    }

    const handleError = () => {
      setIsSubmit(false)
    }

    if (filteredSendData.length > 0) {
      Promise.all([createInterviewsPromise, updateApplicationsPromise])
        .then(handlePromise)
        .catch(handleError)
    } else {
      updateApplicationsPromise.then(handlePromise).catch(handleError)
    }
  }

  const changeModalActiveFlg = () => {
    setModalActiveFlg(!modalActiveFlg)
  }

  function handleModalApply() {
    redirect(`/admin/applications/${recordID}/show`)
  }

  function handleModalCancel() {
    setModalActiveFlg(false)
  }

  return (
    <>
      <Edit className={styles.edit} actions={false} mutationMode="pessimistic">
        <SimpleForm
          className="form"
          sanitizeEmptyValues
          onSubmit={handleFormSubmit}
          toolbar={false}
        >
          <MuiTopToolbar>
            <MuiBreadCrumbs history={['申し込み一覧', `申し込みの詳細`]} />
            <Stack direction="row" spacing={2.5} alignItems="center">
              <MuiButton
                text="キャンセル"
                color="reverse"
                onClick={() => setModalActiveFlg(true)}
              />
              <SaveButton
                className={`${styles.button} ${styles.save}`}
                variant="contained"
                label="保存"
                alwaysEnable
                icon={<></>}
              />
            </Stack>
          </MuiTopToolbar>
          <MuiTopToolbar>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography>
                案件ID：
                <FunctionField
                  source="id"
                  className={styles.responsiblePerson}
                  render={(record: { id: number }) => (
                    <Link
                      href={`/admin/applications/${record.id}/show`}
                      color="link"
                    >
                      {record.id}
                    </Link>
                  )}
                />
              </Typography>
              <Stack direction="row" alignItems="center">
                案件ステータス：
                <ReferenceInput
                  source="status.id"
                  reference="applications/status"
                >
                  <SelectInput
                    variant="outlined"
                    className={styles.selectBox}
                    formClassName={styles.selectInput}
                    label="ステータス"
                    helperText={false}
                    optionText="name"
                  />
                </ReferenceInput>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center">
              担当者：
              <ReferenceInput source="staff.id" reference="users/active">
                <SelectInput
                  variant="outlined"
                  className={styles.selectBox}
                  formClassName={styles.selectInput}
                  label="担当者"
                  helperText={false}
                  optionText="name"
                  optionValue="id"
                  validate={required()}
                />
              </ReferenceInput>
            </Stack>
          </MuiTopToolbar>
          <ContentsTitle title="申し込み情報" />
          <MuiTable>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>
                申し込み日時
              </TableCell>
              <TableCell className={styles.tableRowData}>
                <FunctionField
                  label="申し込み日時"
                  render={(record: { application_date: string }) => (
                    <Typography className={styles.readonly}>
                      {DateTimeUtil(record.application_date)}
                    </Typography>
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>依頼者名</TableCell>
              <TableCell className={styles.tableRowData}>
                <TextInput
                  label="依頼者名"
                  source="customer.name"
                  variant="outlined"
                  helperText={false}
                  validate={required()}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>ふりがな</TableCell>
              <TableCell className={styles.tableRowData}>
                <TextInput
                  label="ふりがな"
                  source="customer.kana"
                  variant="outlined"
                  helperText={false}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>性別</TableCell>
              <TableCell className={styles.tableRowData}>
                <ReferenceInput
                  source="gender.id"
                  reference="applications/gender"
                >
                  <RadioButtonGroupInput
                    optionValue="id"
                    optionText="name"
                    label=""
                    helperText={false}
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>年代</TableCell>
              <TableCell className={styles.tableRowData}>
                <ReferenceInput
                  source="age_group.id"
                  reference="applications/agegroup"
                >
                  <SelectInput
                    variant="outlined"
                    className={styles.selectBox}
                    formClassName={styles.selectInput}
                    label="年代"
                    helperText={false}
                    optionText="name"
                    optionValue="id"
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>都道府県</TableCell>
              <TableCell className={styles.tableRowData}>
                <ReferenceInput source="prefecture.id" reference="prefecture">
                  <SelectInput
                    variant="outlined"
                    className={styles.selectBox}
                    formClassName={styles.selectInput}
                    label="都道府県"
                    helperText={false}
                    optionText="name"
                    optionValue="id"
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>
                メールアドレス
              </TableCell>
              <TableCell className={styles.tableRowData}>
                <TextInput
                  fullWidth
                  label="メールアドレス"
                  source="email"
                  variant="outlined"
                  helperText={false}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>電話番号</TableCell>
              <TableCell className={styles.tableRowData}>
                <TextInput
                  label="電話番号"
                  source="phone_number"
                  variant="outlined"
                  helperText={false}
                  validate={[required(), validatePhoneNumber]}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>
                電話希望日時
              </TableCell>
              <TableCell
                className={`${styles.tableRowData} ${styles.targetFlex}`}
              >
                <DateInput
                  label="電話希望日"
                  source="desired_call.date"
                  variant="outlined"
                  defaultValue={applicationsData.desired_call.date}
                  helperText={false}
                  validate={required()}
                />
                <ReferenceInput
                  source="desired_call.id"
                  reference="applications/calltimes"
                >
                  <SelectInput
                    label="電話希望時間"
                    variant="outlined"
                    optionText="range_time"
                    optionValue="id"
                    helperText={false}
                    validate={required()}
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>特記事項</TableCell>
              <TableCell className={styles.tableRowData}>
                <TextInput
                  variant="outlined"
                  className={`${styles.note} ${styles.shared_note}`}
                  multiline
                  fullWidth
                  placeholder="特記事項があれば記入してください"
                  label={false}
                  helperText={false}
                  source="note"
                  inputProps={{ maxLength: 1000 }}
                />
              </TableCell>
            </TableRow>
          </MuiTable>
          <Box>
            <ContentsTitle title="ヒアリングシート" />
            <MuiTable>
              <TableRow className={styles.tableRow}>
                <TableCell className={styles.tableRowTitle}>予算</TableCell>
                <TableCell
                  className={`${styles.tableRowData} ${styles.targetFlex}`}
                >
                  <TextInput
                    label="予算"
                    source="hearing.budget"
                    variant="outlined"
                    helperText={false}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                      }
                    }}
                  />
                  万円
                </TableCell>
              </TableRow>
              <TableRow className={styles.tableRow}>
                <TableCell className={styles.tableRowTitle}>調査対象</TableCell>
                <TableCell
                  className={`${styles.tableRowData} ${styles.targetFlex}`}
                >
                  <TextInput
                    label="調査対象"
                    source="hearing.target.name"
                    variant="outlined"
                    helperText={false}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={styles.tableRow}>
                <TableCell className={styles.tableRowTitle}>
                  一番気になっている事
                </TableCell>
                <TableCell className={styles.tableRowData}>
                  <ReferenceInput
                    source="hearing.important_point.id"
                    reference="hearings/important_points"
                  >
                    <SelectInput
                      variant="outlined"
                      label="一番気になっている事"
                      optionText="name"
                      optionValue="id"
                      helperText={false}
                    />
                  </ReferenceInput>
                </TableCell>
              </TableRow>
              <TableRow className={styles.tableRow}>
                <TableCell className={styles.tableRowTitle}>調査内容</TableCell>
                <TableCell className={styles.tableRowData}>
                  <ReferenceInput
                    source="hearing.research_type.id"
                    reference="hearings/research"
                  >
                    <SelectInput
                      variant="outlined"
                      label="調査内容"
                      optionText="name"
                      optionValue="id"
                      helperText={false}
                    />
                  </ReferenceInput>
                </TableCell>
              </TableRow>
              <TableRow className={styles.tableRow}>
                <TableCell className={styles.tableRowTitle}>依頼事項</TableCell>
                <TableCell className={styles.tableRowData}>
                  <TextInput
                    label="依頼事項"
                    source="hearing.detail"
                    helperText={false}
                    variant="outlined"
                    fullWidth
                    multiline
                  />
                </TableCell>
              </TableRow>
              <TableRow className={styles.tableRow}>
                <TableCell className={styles.tableRowTitle}>
                  面談のご希望内容
                </TableCell>
                <TableCell className={styles.tableRowData}>
                  <TextInput
                    label="面談のご希望内容"
                    source="hearing.interview_detail"
                    helperText={false}
                    variant="outlined"
                    multiline
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow className={styles.tableRow}>
                <TableCell className={styles.tableRowTitle}>契約確度</TableCell>
                <TableCell className={styles.tableRowData}>
                  <ReferenceInput
                    source="hearing.order_accuracy.id"
                    reference="hearings/accuracies"
                  >
                    <SelectInput
                      variant="outlined"
                      label="契約確度"
                      optionText="name"
                      optionValue="id"
                      helperText={false}
                      emptyValue="null"
                      parse={(value: string) =>
                        value === 'null' ? null : value
                      }
                    />
                  </ReferenceInput>
                </TableCell>
              </TableRow>
            </MuiTable>
          </Box>
          <Box className={styles.margin}>
            <ContentsTitle title="面談予定" />
            <Box className={styles.tableWrap}>
              <Table className={styles.table}>
                <TableHead>
                  <TableRow className={styles.tableRow}>
                    <TableCell
                      className={`${styles.tableRowTitle} ${styles.tableCenter} ${styles.cellShort}`}
                    >
                      面談ID
                    </TableCell>
                    <TableCell
                      className={`${styles.tableRowTitle} ${styles.tableCenter}`}
                    >
                      日時
                    </TableCell>
                    <TableCell
                      className={`${styles.tableRowTitle} ${styles.tableCenter}`}
                    >
                      探偵社
                    </TableCell>
                    <TableCell
                      className={`${styles.tableRowTitle} ${styles.tableCenter}`}
                    >
                      場所
                    </TableCell>
                    <TableCell
                      className={`${styles.tableRowTitle} ${styles.tableCenter} ${styles.cellShort}`}
                    >
                      面談メモ
                    </TableCell>
                    <TableCell
                      className={`${styles.tableRowTitle} ${styles.tableCenter} ${styles.cellShort}`}
                    >
                      詳細
                    </TableCell>
                    <TableCell
                      className={`${styles.tableRowTitle} ${styles.tableCenter} ${styles.cellShort}`}
                    >
                      編集
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <InterviewList
                    interviewList={applicationsData.interviews as Interview[]}
                  />
                  {sendData.length > 0 &&
                    sendData.map((item, index) => {

                      return (
                        <TableRow
                          key={index + item.interview_date}
                          className={styles.tableRow}
                        >
                          <TableCell
                            className={`${styles.tableRowData} ${styles.tableCenter}`}
                          ></TableCell>
                          <TableCell
                            className={`${styles.tableRowData} ${styles.tableCenter}`}
                          >
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={ja}
                            >
                              <DateTimePicker
                                className={
                                  isClicked && errors[index].interview_date
                                    ? `${styles.error}`
                                    : ''
                                }
                                slotProps={{
                                  textField: {
                                    variant: 'outlined',
                                  },
                                  openPickerButton: {
                                    style: {
                                      marginRight: '-6px',
                                    },
                                  },
                                  actionBar: { actions: ['cancel', 'accept'] },
                                }}
                                label="日時"
                                format="yyyy-MM-dd HH:mm"
                                onAccept={(e) => {
                                  if (e !== undefined && e !== null) {
                                    updateChangeSendData(
                                      index,
                                      'interview_date',
                                      e,
                                    )
                                  }
                                }}
                                closeOnSelect={false}
                                defaultValue={
                                  sendData[index].interview_date
                                    ? new Date(sendData[index].interview_date)
                                    : null
                                }
                              />
                            </LocalizationProvider>
                          </TableCell>
                          <TableCell
                            className={`${styles.tableRowData} ${styles.tableCenter}`}
                          >
                            <FormControl fullWidth>
                              <InputLabel>探偵社</InputLabel>
                              <Select
                                variant="outlined"
                                className={
                                  isClicked && errors[index].detective_id
                                    ? `${styles.error}`
                                    : ''
                                }
                                defaultValue={null}
                                value={item.detective.id}
                                label="探偵社"
                                onChange={(e) => {
                                  updateChangeSendData(index, 'detective', {
                                    id: e.target.value,
                                  })
                                  detectivesFunc(index, Number(e.target.value))
                                }}
                              >
                                {detectivesData?.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.id}>
                                      {item.company.name}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell
                            className={`${styles.tableRowData} ${styles.tableCenter}`}
                          >
                            <Typography className={styles.tdText}>
                              {sendData[index].location &&
                                sendData[index].location}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className={`${styles.tableRowData} ${styles.tableCenter}`}
                          ></TableCell>
                          <TableCell
                            className={`${styles.tableRowData} ${styles.tableCenter}`}
                          >
                            {' '}
                          </TableCell>
                          <TableCell
                            className={`${styles.tableRowData} ${styles.tableCenter}`}
                          >
                            {sendData.length > 0 ? (
                              <Button
                                color="error"
                                onClick={() => deleteSendInterviewData(index)}
                              >
                                削除
                              </Button>
                            ) : (
                              ''
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </Box>
            <Button
              startIcon={<AddBoxIcon />}
              onClick={addTableCell}
              className={styles.tableButton}
            >
              面談の追加
            </Button>
          </Box>
          <Box className={styles.margin}>
            <Box className={styles.noteTitle}>
              <ContentsTitle title="申込メモ" />
              <Typography className={styles.comment}>
                <span className={styles.kome}>※</span>
                こちらの内容は共有時に含まれません。
              </Typography>
            </Box>
            <TextInput
              variant="outlined"
              label="メモ"
              className={styles.note}
              multiline
              fullWidth
              helperText={false}
              source="internal_note"
              inputProps={{ maxLength: 1000 }}
            />
          </Box>
          <Stack gap={2.5} direction="row" className={styles.container}>
            <MuiButton
              text="キャンセル"
              color="reverse"
              onClick={() => setModalActiveFlg(true)}
            />
            <SaveButton
              className={`${styles.button} ${styles.save}`}
              variant="contained"
              label="保存"
              icon={<></>}
              alwaysEnable
            />
          </Stack>
        </SimpleForm>
        <TopButton />
      </Edit>
      {modalActiveFlg && (
        <ConfirmModal
          open={modalActiveFlg}
          setOpen={changeModalActiveFlg}
          title="編集内容を破棄しますか？"
          applyText="破棄"
          onClickApply={handleModalApply}
          cancelText="キャンセル"
          onClickCancel={handleModalCancel}
        />
      )}
    </>
  )
}
