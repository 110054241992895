import { Box, Container, Dialog, DialogContent, Stack } from '@mui/material'
import React, { useRef, useCallback } from 'react'
import { useShowContext } from 'react-admin'

import { DateTimeUtil } from '../../../Util/dateUtil'
import { MuiButton } from '../../atom/MuiButton'

import style from './style.module.css'
import { Props } from './type'

export const InterviewDataModal = (props: Props) => {
  const { record } = useShowContext()
  console.log(record)
  const textRef = useRef<HTMLTextAreaElement>(null)

  const copyTextToClipboard = (el: HTMLElement | null) => {
    if (!el) return

    const range = document.createRange()
    range.selectNode(el)

    const selection = window.getSelection()
    if (selection) {
      selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('copy')
      selection.removeAllRanges()
    }
  }

  const handleCopyClick = useCallback(() => {
    copyTextToClipboard(
      textRef.current?.querySelector('.MuiContainer-root') as HTMLElement,
    )
  }, [textRef])

  return record !== null ? (
    <Dialog open={props.open} onClose={props.closeModal} fullWidth>
      <DialogContent ref={textRef}>
        <Container className={style.modalContainer}>
          <Box>
            <Box> ● 面談情報</Box>
            <Stack spacing={0.2}>
              <Box>・面談ID : {record.id}</Box>
              <Box>
                ・場所 :{' '}
                {`${record.detective.prefecture.name}${record.detective.address}`}
              </Box>
              <Box>・日時 : {DateTimeUtil(record.interview_date)}</Box>
              {record.note && (
                <Box className={style.preLine}>
                  ・その他 : <br />
                  {record.note
                    .split('\n')
                    .map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </Box>
              )}
            </Stack>
          </Box>
          <Box>
            <br />
            <Box> ● ヒアリング内容</Box>
            <Stack spacing={0.2}>
              <Box>・予算 : {record.application.hearing.budget}万円</Box>
              <Box>
                ・調査内容 :{record.application.hearing.research_type.name}
              </Box>
              <Box>・調査対象 :{record.application.hearing.target.name}</Box>
              <Box className={style.preLine}>
                ・依頼事項 : <br />
                {record.application.hearing.detail}
              </Box>
              <Box className={style.preLine}>
                ・面談のご希望内容 : <br />
                {record.application.hearing.interview_detail}
              </Box>
            </Stack>
          </Box>
          <Box>
            <br />
            <Box>● 申し込み情報</Box>
            <Stack spacing={0.2}>
              <Box>・依頼者名 : {record.application.customer.name}</Box>
              <Box>・性別 : {record.application.gender.name}</Box>
              <Box>・年代 : {record.application.age_group.name}</Box>
              <Box>・都道府県 : {record.application.prefecture.name}</Box>
              <Box>・電話番号 : {record.application.phone_number}</Box>
            </Stack>
          </Box>
        </Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            mt: 3,
          }}
        >
          <MuiButton onClick={props.closeModal} text="閉じる" color="reverse" />
          <MuiButton onClick={handleCopyClick} text="コピーする" />
        </Box>
      </DialogContent>
    </Dialog>
  ) : (
    <></>
  )
}
