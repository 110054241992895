import SaveAltSharpIcon from '@mui/icons-material/SaveAltSharp'
import axios from 'axios'
import Encoding from 'encoding-japanese'

import { csvDateTimeUtil } from '../../../Util/dateUtil'
import { MuiButton } from '../MuiButton'

import { Props } from './type'

const baseURL = process.env.REACT_APP_PUBLIC_API_ENDPOINT

export const CsvDownloadBtn = (props: Props) => {
  const handleDownloadCsv = () => {
    const queryString = window.location.search

    axios
      .get(`/v1/${props.resources}/csv${queryString}`, {
        baseURL: baseURL,
        headers: new axios.AxiosHeaders({
          'Content-Type': 'text/csv; charset=utf-8',
          Accept: 'text/csv;charset=utf-8',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
      })
      .then((res) => {
        const strArray = Encoding.stringToCode(res.data)
        const convertedArray = Encoding.convert(strArray, 'SJIS', 'UNICODE')
        const UintArray = new Uint8Array(convertedArray)

        const url = window.URL.createObjectURL(
          new Blob([UintArray], { type: 'text/csv' }),
        )
        const link = document.createElement('a')
        link.href = url
        const date = csvDateTimeUtil()
        const fileName =
          props.resources === 'interviews'
            ? `${date}_面談一覧.csv`
            : `${date}_申込一覧.csv`
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
  }
  return (
    <MuiButton
      text="ダウンロード"
      onClick={() => handleDownloadCsv()}
      icon={<SaveAltSharpIcon />}
      color="revert"
    />
  )
}
