import japaneseMessages from '@bicstone/ra-language-japanese'
import polyglotI18nProvider from 'ra-i18n-polyglot'

// https://marmelab.com/react-admin/TranslationLocales.html
// https://github.com/bicstone/ra-language-japanese
export const i18nProvider = polyglotI18nProvider(
  () => japaneseMessages,
  'ja', // default locale
  [{ locale: 'ja', name: 'Japanese' }],
)
