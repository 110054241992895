export type Hearing = {
  budget: string
  target: Target
  research_type: ResearchType
  order_accuracy: OrderAccuracy
  detail: string
  interview_detail: string
  important_point: ImportantPoint
}

export type Target = {
  name: string
  relation: string
}

export type ResearchType = {
  id: number
  name: string
}

export type OrderAccuracy = {
  id: number
  name: string
}

export type ImportantPoint = {
  id: number
  name: string
}

export const ResearchDefaultValue: ResearchType[] = [
  {
    id: 0,
    name: '',
  },
]

export const ImportantPointDefaultValue: ImportantPoint[] = [
  {
    id: 0,
    name: '',
  },
]
