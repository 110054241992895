import { Hearing } from './hearing'
import { Prefecture } from './prefecture'

export type Application = {
  id: number
  application_date: string
  customer: Customer
  age_group: AgeGroup
  gender: Gender
  email: string
  phone_number: string
  desired_call: DesiredCall
  status: Status
  prefecture: Prefecture
  note: string | null
  staff: Staff | null
  hearing: Hearing
}

export type Customer = {
  name: string
  kana: string
}

export type AgeGroup = {
  id: number
  name: string
  from: string
  to: string
}

export type Gender = {
  id: number
  name: string
}

export type DesiredCall = {
  id: number
  date: string
  range_time: string
  from_time: string
  to_time: string
}

export type Status = {
  id: number
  name: string
}

export type Staff = {
  id: number
  name: string
}

export const DefaultValue: Application = {
  id: 0,
  application_date: '',
  customer: {
    name: '',
    kana: '',
  },
  age_group: {
    id: 10,
    name: '',
    from: '',
    to: '',
  },
  gender: {
    id: 0,
    name: '',
  },
  email: '',
  phone_number: '',
  desired_call: {
    id: 0,
    date: '',
    range_time: '',
    from_time: '',
    to_time: '',
  },
  status: {
    id: 0,
    name: '',
  },
  prefecture: {
    id: 1,
    name: '',
  },
  note: null,
  staff: null,
  hearing: {
    budget: '',
    target: {
      name: '',
      relation: '',
    },
    research_type: {
      id: 0,
      name: '',
    },
    order_accuracy: {
      id: 0,
      name: '',
    },
    detail: '',
    interview_detail: '',
    important_point: {
      id: 0,
      name: '',
    },
  },
}

export const GenderDefaultValue: Gender[] = [
  {
    id: 0,
    name: '',
  },
]

export const AgeGroupDefaultValue: AgeGroup[] = [
  {
    id: 10,
    name: '',
    from: '',
    to: '',
  },
]

export const DesireCallDefaultValue: DesiredCall[] = [
  {
    id: 0,
    date: '',
    range_time: '',
    from_time: '',
    to_time: '',
  },
]
