import { Typography } from '@mui/material'
import { useTheme } from '@mui/system'

import style from './style.module.css'
import { Props } from './type'

export const ApplicationStatusBar = ({ status }: Props) => {
  const theme = useTheme()

  let backgroundColor
  switch (status.id) {
    case 0:
      backgroundColor = theme.palette.status.initial
      break
    case 1:
      backgroundColor = theme.palette.status.alert
      break
    case 2:
      backgroundColor = theme.palette.status.valid
      break
    case 3:
      backgroundColor = theme.palette.status.valid
      break
    case 4:
      backgroundColor = theme.palette.status.cancel
      break
    case 5:
      backgroundColor = theme.palette.status.valid
      break
  }

  const styles = {
    backgroundColor,
  }

  return (
    <Typography className={`${style.statusText}`} style={styles}>
      {status.name}
    </Typography>
  )
}
