import {
  TableCell,
  TableRow,
  Box,
  Typography,
  Stack,
  Link,
} from '@mui/material'
import { useState, useEffect } from 'react'
import {
  Edit,
  Form,
  SelectInput,
  ReferenceInput,
  TextInput,
  maxLength,
  useRedirect,
  useGetRecordId,
  SaveButton,
  DateTimeInput,
  Loading,
} from 'react-admin'

import { apiProvider } from '../../../../provider/apiProvider'
import { Interview, DefaultValue } from '../../../../provider/types/interview'
import {
  DateTimeUtil,
  InterviewDate,
  DateUtil,
} from '../../../../Util/dateUtil'
import { DetailIdUtil } from '../../../../Util/detailIdUtil'
import { FormatUtil } from '../../../../Util/formatUtil'
import { ApplicationStatusBar } from '../../../atom/ApplicationStatusBar'
import { ContentsTitle } from '../../../atom/ContentsTitle'
import { MuiButton } from '../../../atom/MuiButton'
import { MuiTopToolbar } from '../../../atom/MuiTopToolbar'
import { OrderAccuracy } from '../../../atom/OrderAccuracy'
import { TopButton } from '../../../atom/TopButton'
import { ConfirmModal } from '../../../organism/ConfirmModal'
import { CustomToolbar } from '../../../organism/CustomToolbar'
import { MuiTable } from '../../../organism/MuiTable'

import style from './style.module.css'
import './global.css'

const validateLength1000 = maxLength(1000)

export const InterviewEdit = () => {
  const recordId = useGetRecordId()
  const [isLoading, setIsLoading] = useState(false)
  const [interviewData, setInterviewData] = useState<Interview>(DefaultValue)
  const redirect = useRedirect()
  const [modalActiveFlg, setModalActiveFlg] = useState(false)

  useEffect(() => {
    if (recordId) {
      apiProvider.getOne('interviews', { id: recordId }).then((response) => {
        console.log(response.data)
        setIsLoading(true)
        setInterviewData({
          ...response.data,
        })
      })
    }
  }, [recordId])

  const updateMemo = () => {
    apiProvider
      .updateInterviewMemo({
        id: recordId,
        data: {
          internal_note: interviewData.internal_note,
        },
      })
      .then(() => {
        console.log('memo updates success')
        redirect(`/admin/interviews/${recordId}/show`)
      })
      .catch(() => {
        console.log('memo updates fail')
      })
  }

  const changeModalActiveFlg = () => {
    setModalActiveFlg(!modalActiveFlg)
  }

  function handleModalApply() {
    redirect(`/admin/interviews/${recordId}/show`)
  }

  function handleModalCancel() {
    setModalActiveFlg(false)
  }

  return isLoading ? (
    <>
      <Edit
        className={style.edit}
        redirect={() => `interviews/${recordId}/show`}
        actions={false}
        mutationMode="pessimistic"
      >
        <Form
          reValidateMode="onBlur"
          sanitizeEmptyValues
          className="form"
          warnWhenUnsavedChanges
        >
          <CustomToolbar history={['面談一覧 ', '面談詳細']}>
            <Stack direction="row" spacing={2.5} alignItems="center">
              <MuiButton
                text="キャンセル"
                color="reverse"
                onClick={() => setModalActiveFlg(true)}
              />
              <SaveButton
                icon={<></>}
                className={style.button}
                variant="contained"
                label="保存"
                alwaysEnable
              />
            </Stack>
          </CustomToolbar>
          <MuiTopToolbar>
            <Stack direction="row" spacing={2} alignItems="center">
              <Stack direction="row">
                案件ID：
                <Link
                  underline="none"
                  className={style.showButton}
                  href={`/admin/applications/${interviewData.application.id}/show`}
                  color="link"
                >
                  {interviewData.application.id}
                </Link>
              </Stack>
              <Stack direction="row" alignItems="center">
                案件ステータス：
                <ApplicationStatusBar
                  status={interviewData.application.status}
                />
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              担当者：
              {interviewData.application.staff?.name ?? '未定'}
            </Stack>
          </MuiTopToolbar>
          <ContentsTitle title="面談情報" />
          <MuiTable>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>面談ID</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {DetailIdUtil(interviewData.id)}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>
                面談ステータス
              </TableCell>
              <TableCell className={style.tableRowData}>
                <ReferenceInput
                  source="status.id"
                  reference="interviews/status"
                >
                  <SelectInput
                    variant="outlined"
                    className={style.selectBox}
                    formClassName={style.selectInput}
                    label="面談ステータス"
                    optionText="name"
                    optionValue="id"
                    helperText={false}
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>面談結果</TableCell>
              <TableCell className={style.tableRowData}>
                <ReferenceInput
                  source="result.id"
                  reference="interviews/results"
                >
                  <SelectInput
                    variant="outlined"
                    className={style.selectBox}
                    formClassName={style.selectInput}
                    label="面談結果"
                    optionText="name"
                    optionValue="id"
                    helperText={false}
                    emptyValue="null"
                    parse={(value: string) => (value === 'null' ? null : value)}
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>探偵社名</TableCell>
              <TableCell className={style.tableRowData}>
                <ReferenceInput source="detective.id" reference="detectives">
                  <SelectInput
                    className={style.selectBox}
                    formClassName={style.selectInput}
                    variant="outlined"
                    helperText={false}
                    label="探偵社名"
                    optionText="company.name"
                    optionValue="id"
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>
                担当者（探偵社）
              </TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.detective.representative.name}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>
                電話番号（探偵社）
              </TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {FormatUtil.formatTelNumber(
                  interviewData.detective.phone_number,
                )}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>
                メールアドレス
              </TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.detective.email}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>場所</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {`${interviewData.detective.prefecture.name}${interviewData.detective.address}`}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>日時</TableCell>
              <TableCell className={style.tableRowData}>
                <DateTimeInput
                  variant="outlined"
                  type="datetime-local"
                  source="interview_date"
                  label="日時"
                  helperText={false}
                  format={InterviewDate}
                  parse={InterviewDate}
                />
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>その他</TableCell>
              <TableCell className={style.tableRowData}>
                <TextInput
                  variant="outlined"
                  className={`${style.note} ${style.shared_note}`}
                  multiline
                  fullWidth
                  placeholder="共有したい情報があれば記入してください"
                  label={false}
                  helperText={false}
                  source="note"
                  validate={validateLength1000}
                />
              </TableCell>
            </TableRow>
          </MuiTable>
          <ContentsTitle title="ヒアリングシート" />
          <MuiTable>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>予算</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.hearing.budget? `${interviewData.application.hearing.budget}万円` : ''} 
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>調査対象</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.hearing.target.name}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>調査内容</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.hearing.research_type.name}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>
                一番気になっている事
              </TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.hearing.important_point.name}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>依頼事項</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.hearing.detail}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>
                面談のご希望内容
              </TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.hearing.interview_detail}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>契約確度</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.hearing.order_accuracy ? (
                  <OrderAccuracy
                    status={interviewData.application.hearing.order_accuracy}
                  />
                ) : (
                  '　'
                )}
              </TableCell>
            </TableRow>
          </MuiTable>
          <ContentsTitle title="申し込み情報" />
          <MuiTable>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>
                申し込み日時
              </TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {DateTimeUtil(interviewData.application.application_date)}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>依頼者名</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.customer.name}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>ふりがな</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.customer.kana}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>性別</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.gender.name}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>年代</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.age_group.name}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>都道府県</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.prefecture.name}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>
                メールアドレス
              </TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.email}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>電話番号</TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {interviewData.application.phone_number}
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>
                電話希望日時
              </TableCell>
              <TableCell className={`${style.tableRowData} ${style.disable}`}>
                {`${DateUtil(interviewData.application.desired_call.date)} ${
                  interviewData.application.desired_call.range_time
                }`}
              </TableCell>
            </TableRow>
          </MuiTable>
          <Box>
            <Stack direction="row">
              <ContentsTitle title="面談メモ" />
              <Typography className={style.comment}>
                <span className={style.kome}>※</span>
                こちらの内容は共有時に含まれません。
              </Typography>
            </Stack>
            <TextInput
              className={style.note}
              multiline
              fullWidth
              label={false}
              helperText={false}
              source="internal_note"
              validate={validateLength1000}
              onChange={(e) =>
                setInterviewData({
                  ...interviewData,
                  internal_note: e.target.value,
                })
              }
            />
          </Box>
          <Stack
            direction="row"
            spacing={2.5}
            alignItems="center"
            className={style.container}
          >
            <MuiButton
              text="キャンセル"
              color="reverse"
              onClick={() => setModalActiveFlg(true)}
            />
            <SaveButton
              icon={<></>}
              className={style.button}
              variant="contained"
              mutationOptions={{
                onSuccess: () => {
                  updateMemo()
                },
              }}
              label="保存"
              alwaysEnable
            />
          </Stack>
        </Form>
        <TopButton />
      </Edit>
      {modalActiveFlg && (
        <ConfirmModal
          open={modalActiveFlg}
          setOpen={changeModalActiveFlg}
          title="編集内容を破棄しますか？"
          applyText="破棄"
          onClickApply={handleModalApply}
          cancelText="キャンセル"
          onClickCancel={handleModalCancel}
        />
      )}
    </>
  ) : (
    <Loading />
  )
}
