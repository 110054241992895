import { TopArrowIcon } from '../TopArrowIcon'

import style from './style.module.css'

const handleScrollTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

export const TopButton = () => {
  return (
    <button className={style.button} onClick={handleScrollTop}>
      <TopArrowIcon />
      <div>Topへ</div>
    </button>
  )
}
