import { Box, Typography } from '@mui/material'
import { useState, useRef, useCallback } from 'react'
import { UpdateResult } from 'react-admin'

import { apiProvider } from '../../../provider/apiProvider'
import { MuiButton } from '../../atom/MuiButton'
import { Spinner } from '../../atom/Spinner'
import { MuiModal } from '../../organism/MuiModal'
import { ErrorModal } from '../ErrorModal'

import style from './style.module.css'
import { Props } from './type'

export const PasswordResetModal = (props: Props) => {
  // API呼ぶ時のSpinner
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const textRef = useRef<HTMLTextAreaElement>(null)

  const resetPassword = () => {
    setIsLoading(true)
    apiProvider
      .updatePassword({ id: props.user.id })
      .then((response: UpdateResult) => {
        setIsLoading(false)
        setPassword(response.data.password)
      })
      .catch(() => {
        setIsLoading(false)
        props.closeModal()

        return <ErrorModal />
      })
  }

  const copyTextToClipboard = (el: HTMLElement | null) => {
    if (!el) return

    const range = document.createRange()
    range.selectNode(el)

    const selection = window.getSelection()
    if (selection) {
      selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('copy')
      selection.removeAllRanges()
    }
  }

  const handleCopyClick = useCallback(() => {
    copyTextToClipboard(
      textRef.current?.querySelector('.MuiBox-root') as HTMLElement,
    )
    console.log(textRef)
  }, [textRef])

  return (
    <MuiModal open={props.open} closeModal={props.closeModal}>
      <Typography className={style.modalTitle}>パスワードの再発行</Typography>
      {isLoading ? (
        <Box>
          <Spinner />
          <Typography className={style.modalComment}>
            パスワードを作成中です。
          </Typography>
        </Box>
      ) : (
        <>
          {password === '' ? (
            <Box>
              <Typography className={style.modalComment}>
                以下のアカウントのパスワードを再発行しますか
              </Typography>
              <Box className={style.modalContentsContainer}>
                <Typography className={style.infoText}>
                  氏名：{props.user.name}
                </Typography>
                <Typography className={style.infoText}>
                  ログインID：{props.user.login_id}
                </Typography>
              </Box>
              <Box className={style.modalButtonContainer}>
                <MuiButton
                  onClick={props.closeModal}
                  text="キャンセル"
                  color="reverse"
                />
                <MuiButton onClick={resetPassword} text="再発行する" />
              </Box>
            </Box>
          ) : (
            <Box ref={textRef}>
              <Typography className={style.modalComment}>
                パスワードを再発行しました。
                <br />
                以下のIDとパスワードでログインしてください。
              </Typography>
              <Box className={style.modalContentsContainer}>
                <Typography className={style.infoText}>
                  氏名：{props.user.name}
                </Typography>
                <Typography className={style.infoText}>
                  ログインID：{props.user.login_id}
                </Typography>
                {password ? (
                  <Typography className={style.infoText}>
                    パスワード：{password}
                  </Typography>
                ) : (
                  ''
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 2,
                  mt: 3,
                }}
              >
                <MuiButton
                  onClick={props.closeModal}
                  text="閉じる"
                  color="reverse"
                />
                <MuiButton onClick={handleCopyClick} text="コピーする" />
              </Box>
            </Box>
          )}
        </>
      )}
    </MuiModal>
  )
}
