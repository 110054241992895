export type Prefecture = {
  id: number
  name: string
}

export const PrefectureDefaultValue: Prefecture[] = [
  {
    id: 0,
    name: '',
  },
]
