import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { useState } from 'react'
import {
  Create,
  Form,
  TextInput,
  ReferenceInput,
  SelectInput,
  useRedirect,
  SaveButton,
} from 'react-admin'

import { Validate, ValidateForm } from '../../../../provider/validate/detective'
import { ContentsTitle } from '../../../atom/ContentsTitle'
import { MuiButton } from '../../../atom/MuiButton'
import { Spinner } from '../../../atom/Spinner'
import { ConfirmModal } from '../../../organism/ConfirmModal'
import { CustomToolbar } from '../../../organism/CustomToolbar'
import { MuiModal } from '../../../organism/MuiModal'
import { MuiTable } from '../../../organism/MuiTable'

import style from './style.module.css'
import './global.css'

export const DetectiveCreate = () => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const redirect = useRedirect()
  const [modalActiveFlg, setModalActiveFlg] = useState(false)

  // バリデーションの結果に応じた処理のためオーバーラップ
  const validate = (values: Validate) => {
    let errors: Record<string, string> = {}
    errors = ValidateForm(values)

    if (Object.keys(errors).length > 0) {
      setIsValid(false)
      return errors
    } else {
      setIsLoading(true)
      setIsValid(true)
      return {}
    }
  }

  const changeModalActiveFlg = () => {
    setModalActiveFlg(!modalActiveFlg)
  }

  function handleModalApply() {
    redirect(`/admin/detectives`)
  }

  function handleModalCancel() {
    setModalActiveFlg(false)
  }

  return (
    <>
      <Create className={style.create} redirect="list">
        <Form validate={validate} reValidateMode="onBlur" className="form">
          <CustomToolbar history={['探偵社一覧', `探偵社の新規追加`]}>
            <Stack direction="row" spacing={2.5} alignItems="center">
              <MuiButton
                text="キャンセル"
                color="reverse"
                onClick={() => setModalActiveFlg(true)}
              />
              <SaveButton
                className={style.button}
                disabled={isDisabled}
                type="submit"
                variant="contained"
                onClick={() => (isValid ? setOpen(true) : setOpen(false))}
                label="保存"
                icon={<></>}
              />
            </Stack>
          </CustomToolbar>
          <MuiTable>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>探偵社名</TableCell>
              <TableCell className={style.tableRowData}>
                <TextInput
                  onChange={(e) => {
                    e.target.value === ''
                      ? setIsDisabled(true)
                      : setIsDisabled(false)
                  }}
                  variant="outlined"
                  label="探偵社名"
                  source="company.name"
                  helperText={false}
                  fullWidth
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      event.stopPropagation()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>ふりがな</TableCell>
              <TableCell className={style.tableRowData}>
                <TextInput
                  variant="outlined"
                  label="ふりがな"
                  source="company.kana"
                  helperText={false}
                  fullWidth
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      event.stopPropagation()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>ステータス</TableCell>
              <TableCell className={style.tableRowData}>
                <ReferenceInput
                  source="status.id"
                  reference="detectives/status"
                >
                  <SelectInput
                    variant="outlined"
                    label="ステータス"
                    optionText="name"
                    optionValue="id"
                    helperText={false}
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>担当者</TableCell>
              <TableCell className={style.tableRowData}>
                <TextInput
                  variant="outlined"
                  label="担当者"
                  source="representative.name"
                  helperText={false}
                  fullWidth
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      event.stopPropagation()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>ふりがな</TableCell>
              <TableCell className={style.tableRowData}>
                <TextInput
                  variant="outlined"
                  label="ふりがな"
                  source="representative.kana"
                  helperText={false}
                  fullWidth
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      event.stopPropagation()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>電話番号</TableCell>
              <TableCell className={style.tableRowData}>
                <TextInput
                  variant="outlined"
                  label="電話番号"
                  source="phone_number"
                  helperText={false}
                  fullWidth
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      event.stopPropagation()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>
                メールアドレス
              </TableCell>
              <TableCell className={style.tableRowData}>
                <TextInput
                  variant="outlined"
                  label="メールアドレス"
                  source="email"
                  helperText={false}
                  fullWidth
                />
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>都道府県</TableCell>
              <TableCell className={style.tableRowData}>
                <ReferenceInput source="prefecture.id" reference="prefecture">
                  <SelectInput
                    variant="outlined"
                    className={style.selectBox}
                    formClassName={style.selectInput}
                    label="都道府県"
                    helperText={false}
                    optionText="name"
                    optionValue="id"
                  />
                </ReferenceInput>
              </TableCell>
            </TableRow>
            <TableRow className={style.tableRow}>
              <TableCell className={style.tableRowTitle}>住所</TableCell>
              <TableCell className={style.tableRowData}>
                <TextInput
                  variant="outlined"
                  label="住所"
                  source="address"
                  multiline
                  helperText={false}
                  fullWidth
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      event.stopPropagation()
                    }
                  }}
                />
              </TableCell>
            </TableRow>
          </MuiTable>
          <Box>
            <ContentsTitle title="探偵社メモ" />
            <TextInput
              variant="outlined"
              className={style.note}
              multiline
              fullWidth
              label="探偵社メモ"
              helperText={false}
              source="internal_note"
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  event.stopPropagation()
                }
              }}
            />
          </Box>
          <Stack gap={2.5} direction="row" className={style.container}>
            <MuiButton
              text="キャンセル"
              color="reverse"
              onClick={() => setModalActiveFlg(true)}
            />
            <SaveButton
              className={style.button}
              disabled={isDisabled}
              type="submit"
              variant="contained"
              onClick={() => (isValid ? setOpen(true) : setOpen(false))}
              label="保存"
              icon={<></>}
            />
          </Stack>
        </Form>
      </Create>
      <MuiModal
        open={open}
        closeModal={() => setOpen(false)}
        title="探偵社の新規追加"
      >
        {isLoading ? (
          <Box>
            <Spinner />
            <Typography className={style.modalComment}>
              探偵社の新規追加中です。
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography className={style.modalComment}>
              探偵社の新規追加が完了しました。
            </Typography>

            <Box className={style.modalButtonContainer}>
              <MuiButton
                onClick={() => setOpen(false)}
                text="閉じる"
                color="white"
              />
              <MuiButton
                onClick={() => redirect('/admin/detectives')}
                text="一覧へ戻る"
                color="green"
              />
            </Box>
          </Box>
        )}
      </MuiModal>
      {modalActiveFlg && (
        <ConfirmModal
          open={modalActiveFlg}
          setOpen={changeModalActiveFlg}
          title="新規追加を破棄しますか？"
          applyText="破棄"
          onClickApply={handleModalApply}
          cancelText="キャンセル"
          onClickCancel={handleModalCancel}
        />
      )}
    </>
  )
}
