import ExitIcon from '@mui/icons-material/PowerSettingsNew'
import MenuItem from '@mui/material/MenuItem'
import { useLogout } from 'react-admin'

// It's important to pass the ref to allow Material UI to manage the keyboard navigation
export const LogoutButton = () => {
  const logout = useLogout()
  const handleClick = () => logout('/login')
  return (
    <MenuItem onClick={handleClick}>
      <ExitIcon /> Logout
    </MenuItem>
  )
}
