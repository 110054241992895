import { SvgIcon } from '@mui/material'

import style from './style.module.css'

export const TopArrowIcon = () => {
  return (
    <SvgIcon className={style.button}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="11"
        viewBox="0 0 18 11"
        fill="none"
      >
        <path
          d="M16 8.84444L9 2L2 8.84444"
          stroke="#457670"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
