import { SvgIcon } from '@mui/material'

export const MarkIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="23"
        viewBox="0 0 25 23"
        fill="none"
      >
        <path
          d="M7.29167 11.5002C7.29167 12.0085 7.07217 12.496 6.68147 12.8555C6.29077 13.2149 5.76087 13.4168 5.20833 13.4168C4.6558 13.4168 4.12589 13.2149 3.73519 12.8555C3.34449 12.496 3.125 12.0085 3.125 11.5002C3.125 10.9918 3.34449 10.5043 3.73519 10.1449C4.12589 9.78543 4.6558 9.5835 5.20833 9.5835C5.76087 9.5835 6.29077 9.78543 6.68147 10.1449C7.07217 10.5043 7.29167 10.9918 7.29167 11.5002ZM14.5833 11.5002C14.5833 12.0085 14.3638 12.496 13.9731 12.8555C13.5824 13.2149 13.0525 13.4168 12.5 13.4168C11.9475 13.4168 11.4176 13.2149 11.0269 12.8555C10.6362 12.496 10.4167 12.0085 10.4167 11.5002C10.4167 10.9918 10.6362 10.5043 11.0269 10.1449C11.4176 9.78543 11.9475 9.5835 12.5 9.5835C13.0525 9.5835 13.5824 9.78543 13.9731 10.1449C14.3638 10.5043 14.5833 10.9918 14.5833 11.5002ZM21.875 11.5002C21.875 12.0085 21.6555 12.496 21.2648 12.8555C20.8741 13.2149 20.3442 13.4168 19.7917 13.4168C19.2391 13.4168 18.7092 13.2149 18.3185 12.8555C17.9278 12.496 17.7083 12.0085 17.7083 11.5002C17.7083 10.9918 17.9278 10.5043 18.3185 10.1449C18.7092 9.78543 19.2391 9.5835 19.7917 9.5835C20.3442 9.5835 20.8741 9.78543 21.2648 10.1449C21.6555 10.5043 21.875 10.9918 21.875 11.5002Z"
          fill="#457670"
        />
      </svg>
    </SvgIcon>
  )
}
