import { AddCircleOutline } from '@mui/icons-material'
import {
  Datagrid,
  FunctionField,
  List,
  ShowButton,
  TextField,
  usePermissions,
} from 'react-admin'

import { DateUtil } from '../../../../Util/dateUtil'
import { FormatUtil } from '../../../../Util/formatUtil'
import { CsvDownloadBtn } from '../../../atom/CsvDownloadBtn'
import { DetailLink } from '../../../atom/DetailLink'
import { MuiButton } from '../../../atom/MuiButton'
import { ApplicationListFilters } from '../../../feature/Application/ApplicationListFilters'
import { CustomToolbar } from '../../../organism/CustomToolbar'
import { DateTimeItems } from '../../../organism/DateTimeItems'
import { MuiTooltip } from '../../../organism/MuiTooltip'

import './global.css'
import styles from './style.module.css'

const statusClassFunc = (status: number) => {
  if (status === 0) {
    return styles.unSupported
  } else if (status === 1) {
    return styles.inSupported
  } else if (status === 2) {
    return styles.inStallation
  } else if (status === 3) {
    return styles.inValid
  } else if (status === 4) {
    return styles.cancel
  } else if (status === 5) {
    return styles.complete
  }
}

const CustomEmpty = () => <div>表示できるものがありません。</div>

export const ApplicationList = () => {
  const { permissions } = usePermissions()

  const handleAddNewApplication = () => {
    window.location.href = '/admin/applications/create'
  }

  return (
    <div className={styles.applicationListWrap}>
      <List
        actions={false}
        className={styles.tableWrap}
        empty={<CustomEmpty />}
      >
        <CustomToolbar history={['申し込み一覧']}>
          <MuiButton
            text="新規申込"
            icon={<AddCircleOutline />}
            onClick={handleAddNewApplication}
            color="reverse"
          />
          {permissions === 'admin' && (
            <CsvDownloadBtn resources="applications" />
          )}
        </CustomToolbar>

        <ApplicationListFilters />

        <Datagrid className={styles.table} bulkActionButtons={false}>
          <FunctionField
            label={'案件ID'}
            source="id"
            className={styles.tableCenter}
            render={(record: { id: number }) => (
              <DetailLink resource="applications" id={record.id} />
            )}
          />
          <FunctionField
            label={'申込日'}
            className={styles.tableCenter}
            source="application_date"
            render={(record: { application_date: string }) =>
              DateUtil(record.application_date)
            }
          />
          <TextField
            label={'依頼者'}
            className={styles.tableCenter}
            sortBy="name_kana"
            source="customer.name"
          />
          <FunctionField
            label={'電話番号'}
            source="phone_number"
            className={styles.tableCenter}
            render={(record: { phone_number: string }) =>
              FormatUtil.formatTelNumber(record.phone_number)
            }
          />
          <FunctionField
            label="ステータス"
            sortBy="status"
            className={styles.tableCenter}
            render={(record: { status: { id: number; name: string } }) => (
              <span
                className={`${styles.status} ${statusClassFunc(
                  record.status.id,
                )}`}
              >
                {record.status.name}
              </span>
            )}
          />
          <FunctionField
            label="電話希望日時"
            sortBy="desired_call_from"
            className={styles.tableCenter}
            render={(record: {
              desired_call: { date: string; range_time: string }
            }) => (
              <DateTimeItems
                dateTimes={`${record.desired_call.date} ${record.desired_call.range_time}`}
              />
            )}
          />
          <FunctionField
            label="調査内容"
            source="hearing.research_type"
            sortBy="hearings.investigation_content"
            className={styles.tableCenter}
            render={(record: {
              hearing: { research_type: { id: number; name: string } }
            }) =>
              record.hearing.research_type
                ? record.hearing.research_type.name
                : ''
            }
          />
          <FunctionField
            source="staff.id"
            label="担当者"
            sortBy="staff_id"
            className={styles.tableCenter}
            render={(record: { staff: { id: number; name: string } }) =>
              record.staff ? record.staff.name : '-'
            }
          />
          <FunctionField
            label={'メモ'}
            className={styles.tableCenter}
            render={(record: { internal_note: string; id: number }) => (
              <MuiTooltip
                id={record.id}
                title="申込メモ"
                text={record.internal_note}
                category="application"
              />
            )}
          />
          <ShowButton
            className={`${styles.tableCenter} ${styles.showButton}`}
            label={'詳細'}
            icon={<></>}
          />
        </Datagrid>
      </List>
    </div>
  )
}
