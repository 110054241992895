import {
  UserMenu as RAUserMenu,
  AppBar as RAAppBar,
  Layout as RALayout,
  LayoutProps,
} from 'react-admin'

import { LogoutButton } from './components/organism/LogoutButton'
import { MyMenu } from './components/template/MyMenu'

const UserMenu = () => (
  <RAUserMenu>
    <LogoutButton />
  </RAUserMenu>
)

const AppBar = () => <RAAppBar color="secondary" userMenu={<UserMenu />} />

export const Layout = (props: LayoutProps) => {
  return (
    <>
      <RALayout {...props} appBar={AppBar} menu={MyMenu}>
        {props.children}
      </RALayout>
    </>
  )
}
