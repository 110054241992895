import { useState } from 'react'
import {
  Datagrid,
  List,
  ShowButton,
  TextField,
  usePermissions,
  FunctionField,
  ListButton,
} from 'react-admin'

import { Status } from '../../../../provider/types/user'
import { MuiButton } from '../../../atom/MuiButton'
import { StatusText } from '../../../atom/StatusText'
import { AccountCreateModal } from '../../../feature/User/AccountCreateModal'
import { UserListFilters } from '../../../feature/User/UserListFilters'
import { CustomToolbar } from '../../../organism/CustomToolbar'

import style from './style.module.css'

import './global.css'

export const UserList = () => {
  const [open, setOpen] = useState(false)
  const { permissions } = usePermissions()

  return (
    <>
      <List actions={false}>
        <CustomToolbar history={['アカウント一覧']}>
          {permissions === 'admin' && (
            <MuiButton onClick={() => setOpen(true)} text="アカウントの作成" />
          )}
        </CustomToolbar>

        <UserListFilters />

        <Datagrid bulkActionButtons={false} className="list">
          <TextField label="氏名" source="name" />
          <TextField label="ID" source="login_id" />
          <TextField
            label="権限"
            source="permission.name"
            sortBy="permission_id"
          />
          <FunctionField
            label={'ステータス'}
            source="status"
            sortBy="status"
            render={(record: { status: Status }) => (
              <StatusText status={record.status} />
            )}
          />
          <ShowButton label="詳細" startIcon={null} className={style.button} />
        </Datagrid>

        {open && (
          <AccountCreateModal open={open} closeModal={() => setOpen(false)} />
        )}
      </List>
      <ListButton
        label="操作ログ"
        resource="activitylog"
        startIcon={null}
        className={style.link}
      />
    </>
  )
}
