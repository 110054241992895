import { DateUtil } from '../../../Util/dateUtil'

import styles from './style.module.css'

export const DateTimeItems = ({ dateTimes }: { dateTimes: string }) => {
  const dateRegex = /\d{4}.\d{2}.\d{2}/g
  const datefound = dateTimes.match(dateRegex)
  const timeRegex = /\d{2}:\d{2}/g
  const timefound = dateTimes.match(timeRegex)

  const checkDateFound = null !== datefound
  const checkTimeFound = null !== timefound
  const checkSecondTimeFound = checkTimeFound && 1 < timefound.length

  return (
    <span className={styles.align}>
      {/* 日付 */}
      {checkDateFound && (
        <>
          {DateUtil(datefound[0])}
          <br />
        </>
      )}
      {/* 時間 */}
      {checkTimeFound && (
        <>
          {timefound[0]}
          {checkSecondTimeFound && `-${timefound[1]}`}
        </>
      )}
    </span>
  )
}
