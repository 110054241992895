import { StyledEngineProvider } from '@mui/material/styles'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { jaJP } from '@mui/x-date-pickers/locales'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { Admin } from './Admin'
import { Login } from './components/template/Login'

const theme = createTheme({}, jaJP)

const App = () => (
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/admin/login"
            element={<Navigate replace to="/login" />}
          />
          <Route path="/admin/*" element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </StyledEngineProvider>
  </ThemeProvider>
)

export default App
