export type User = {
  id: number
  name: string
  login_id: string
  email?: string
  permission: Permission
  status: Status
  internal_note: string | null
}

export type Permission = {
  id: number
  name: string
  key: string
}

export type Status = {
  id: number
  name: string
}

export const DefaultValue: User = {
  id: 0,
  name: '',
  login_id: '',
  email: '',
  permission: {
    id: 2,
    name: '',
    key: '',
  },
  status: {
    id: 0,
    name: '',
  },
  internal_note: '',
}

export type SendPassword = {
  login_id: string
  current_password: string
  new_password: string
  new_password_confirmation: string
}

export type CreateUser = {
  name: string
  login_id: string
  permission: Permission
}

export const RegisterDefaultValue: CreateUser = {
  name: '',
  login_id: '',
  permission: {
    id: 2,
    name: 'テレオペ',
    key: 'operator',
  },
}
