import { Box, TableCell, TableRow, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  Form,
  TextInput,
  useGetRecordId,
  useRedirect,
  Loading,
  Edit,
  maxLength,
} from 'react-admin'

import { apiProvider } from '../../../../provider/apiProvider'
import { Detective, DefaultValue } from '../../../../provider/types/detective'
import { FormatUtil } from '../../../../Util/formatUtil'
import { ContentsTitle } from '../../../atom/ContentsTitle'
import { MuiButton } from '../../../atom/MuiButton'
import { CustomToolbar } from '../../../organism/CustomToolbar'
import { MuiTable } from '../../../organism/MuiTable'

import style from './style.module.css'
import './global.css'

export const DetectiveOperatorEdit = () => {
  const recordId = useGetRecordId()
  const [isLoading, setIsLoading] = useState(true)
  const redirect = useRedirect()
  const [detective, setDetective] = useState<Detective>(DefaultValue)

  useEffect(() => {
    if (recordId) {
      apiProvider.getOne('detectives', { id: recordId }).then((response) => {
        setIsLoading(false)
        setDetective(response.data)
      })
    }
  }, [recordId])

  const handleSaveMemo = () => {
    console.log(detective)
    apiProvider
      .updateDetectiveMemo({
        id: recordId,
        data: {
          internal_note: detective.internal_note,
        },
      })
      .then(() => {
        redirect(`/admin/detectives/${recordId}/show`)
      })
  }
  return isLoading ? (
    <Loading />
  ) : (
    <Edit className={style.edit} actions={false}>
      <Form className="form">
        <CustomToolbar history={['探偵社一覧', `${detective.company.name}`]}>
          <Stack direction="row" spacing={2.5} alignItems="center">
            <MuiButton
              text="キャンセル"
              color="reverse"
              onClick={() => redirect(`/admin/detectives/${detective.id}/show`)}
            />
            <MuiButton onClick={handleSaveMemo} text="保存" />
          </Stack>
        </CustomToolbar>
        <ContentsTitle title="基本情報" />
        <MuiTable>
          <TableRow className={style.tableRow}>
            <TableCell className={style.tableRowTitle}>探偵社名</TableCell>
            <TableCell className={`${style.tableRowData} ${style.disabled}`}>
              {`${detective.company.name}(${detective.company.kana})`}
            </TableCell>
          </TableRow>
          <TableRow className={style.tableRow}>
            <TableCell className={style.tableRowTitle}>ステータス</TableCell>
            <TableCell className={`${style.tableRowData} ${style.disabled}`}>
              {detective.status.name}
            </TableCell>
          </TableRow>
          <TableRow className={style.tableRow}>
            <TableCell className={style.tableRowTitle}>担当者</TableCell>
            <TableCell className={`${style.tableRowData} ${style.disabled}`}>
              {`${detective.representative.name}(${detective.representative.kana})`}
            </TableCell>
          </TableRow>
          <TableRow className={style.tableRow}>
            <TableCell className={style.tableRowTitle}>電話番号</TableCell>
            <TableCell className={`${style.tableRowData} ${style.disabled}`}>
              {FormatUtil.formatTelNumber(detective.phone_number)}
            </TableCell>
          </TableRow>
          <TableRow className={style.tableRow}>
            <TableCell className={style.tableRowTitle}>
              メールアドレス
            </TableCell>
            <TableCell className={`${style.tableRowData} ${style.disabled}`}>
              {detective.email}
            </TableCell>
          </TableRow>
          <TableRow className={style.tableRow}>
            <TableCell className={style.tableRowTitle}>都道府県</TableCell>
            <TableCell className={`${style.tableRowData} ${style.disabled}`}>
              {detective.prefecture.name}
            </TableCell>
          </TableRow>
          <TableRow className={style.tableRow}>
            <TableCell className={style.tableRowTitle}>住所</TableCell>
            <TableCell className={`${style.tableRowData} ${style.disabled}`}>
              {detective.address}
            </TableCell>
          </TableRow>
        </MuiTable>
        <Box>
          <ContentsTitle title="探偵社メモ" />
          <TextInput
            variant="outlined"
            label="探偵社メモ"
            className={style.note}
            multiline
            fullWidth
            helperText={false}
            source="internal_note"
            validate={maxLength(1000)}
            onChange={(e) =>
              setDetective({ ...detective, internal_note: e.target.value })
            }
          />
        </Box>
        <Stack gap={2.5} direction="row" className={style.container}>
          <MuiButton
            text="キャンセル"
            color="reverse"
            onClick={() => redirect(`/admin/detectives/${detective.id}/show`)}
          />
          <MuiButton onClick={handleSaveMemo} text="保存" />
        </Stack>
      </Form>
    </Edit>
  )
}
