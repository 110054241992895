import { Stack, Typography, Grid, Button } from '@mui/material'

import { MuiPre } from '../../../atom/MuiPre'

import { CompleteProps } from './type'

const copy = (accountInfo: string) => {
  navigator.clipboard.writeText(accountInfo)
}

export const Complete = ({ accountInfo, closeModal }: CompleteProps) => (
  <Stack spacing={1.5} sx={{ margin: '0 -20px' }}>
    <Typography paragraph align="center">
      アカウントを作成しました。
      <br />
      以下のIDと仮パスワードでログインしてください。
    </Typography>
    <MuiPre>{accountInfo}</MuiPre>

    <Grid container spacing={1} columns={2}>
      <Grid item xs>
        <Button
          type="button"
          variant="contained"
          color="reverse"
          fullWidth
          onClick={closeModal}
        >
          閉じる
        </Button>
      </Grid>
      <Grid item xs>
        <Button
          type="button"
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => copy(accountInfo)}
        >
          コピーする
        </Button>
      </Grid>
    </Grid>
  </Stack>
)
