import { IconButton, Link, TableCell, TableRow } from '@mui/material'
import { FunctionField, useRedirect } from 'react-admin'

import { Interview } from '../../../../provider/types/interview'
import { DateTimeUtil } from '../../../../Util/dateUtil'
import { DetailLink } from '../../../atom/DetailLink'
import { MarkIcon } from '../../../atom/MarkIcon'
import { MuiTooltip } from '../../../organism/MuiTooltip'

import styles from './style.module.css'

export const InterviewList = ({
  interviewList,
}: {
  interviewList: Interview[]
}) => {
  const redirect = useRedirect()
  return (
    <>
      {interviewList.length > 0 ? (
        interviewList.map((item, index) => (
          <TableRow key={index}>
            <TableCell
              className={`${styles.tableRowData} ${styles.tableCenter}`}
            >
              <FunctionField
                label={'面談ID'}
                render={(record: { interviews: Interview[] }) => (
                  <DetailLink
                    resource="interviews"
                    id={record.interviews[index].id}
                  />
                )}
              />
            </TableCell>
            <TableCell
              className={`${styles.tableRowData} ${styles.tableCenter}`}
            >
              {DateTimeUtil(item.interview_date)}
            </TableCell>
            <TableCell
              className={`${styles.tableRowData} ${styles.tableCenter}`}
            >
              {item.detective.company.name}
            </TableCell>
            <TableCell
              className={`${styles.tableRowData} ${styles.tableCenter}`}
            >
              {item.location}
            </TableCell>
            <TableCell
              className={`${styles.tableRowData} ${styles.tableCenter}`}
            >
              <FunctionField
                label="面談メモ"
                render={(record: { interviews: Interview[] }) => (
                  <MuiTooltip
                    id={record.interviews[index].id}
                    title="面談メモ"
                    text={
                      record.interviews[index].internal_note
                        ? (record.interviews[index].internal_note as string)
                        : ''
                    }
                    category="interview"
                  />
                )}
              />
            </TableCell>
            <TableCell
              className={`${styles.tableRowData} ${styles.tableCenter}`}
            >
              <FunctionField
                label={'詳細'}
                render={(record: { interviews: Interview[] }) => (
                  <Link
                    className={styles.link}
                    href={`/admin/interviews/${record.interviews[index].id}/show`}
                  >
                    {'詳細'}
                  </Link>
                )}
              />
            </TableCell>
            <TableCell
              className={`${styles.tableRowData} ${styles.tableCenter}`}
            >
              <IconButton
                onClick={() => redirect('edit', 'interviews', item.id)}
              >
                <MarkIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <></>
      )}
    </>
  )
}
