import { Box } from '@mui/material'

import style from './style.module.css'
import { TabPanelProps } from './type'

export const TabItem = (props: TabPanelProps) => {
  const { children, value, index } = props

  return (
    <div
      className={style.contents}
      role="tabpanel"
      hidden={value !== index}
      id={`${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
