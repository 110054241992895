import { Box, TableCell, TableRow, FormControl, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  Edit,
  Form,
  useGetRecordId,
  useRedirect,
  ReferenceInput,
  SelectInput,
  TextInput,
  SaveButton,
  required,
  maxLength,
  Loading,
} from 'react-admin'

import { apiProvider } from '../../../../provider/apiProvider'
import { User, DefaultValue } from '../../../../provider/types/user'
import { ContentsTitle } from '../../../atom/ContentsTitle'
import { MuiButton } from '../../../atom/MuiButton'
import { ConfirmModal } from '../../../organism/ConfirmModal'
import { CustomToolbar } from '../../../organism/CustomToolbar'
import { MuiTable } from '../../../organism/MuiTable'

import style from './style.module.css'

export const UserEdit = () => {
  const recordId = useGetRecordId()
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState<User>(DefaultValue)
  const redirect = useRedirect()
  const [modalActiveFlg, setModalActiveFlg] = useState(false)

  const nameValidation = [required(), maxLength(100)]

  // リストデータ取得
  useEffect(() => {
    if (recordId) {
      apiProvider.getOne('users', { id: recordId }).then((res) => {
        setIsLoading(true)
        setUser(res.data)
      })
    }
  }, [recordId])

  const changeModalActiveFlg = () => {
    setModalActiveFlg(!modalActiveFlg)
  }

  function handleModalApply() {
    redirect(`/admin/users/${user.id}/show`)
  }

  function handleModalCancel() {
    setModalActiveFlg(false)
  }

  return (
    <>
      <Edit className={style.edit} actions={false} mutationMode="pessimistic">
        {isLoading === true ? (
          <Form sanitizeEmptyValues>
            <CustomToolbar
              history={['アカウント一覧', `${user.name}さんの詳細`]}
            >
              <Stack direction="row" spacing={2.5} alignItems="center">
                <MuiButton
                  text="キャンセル"
                  color="reverse"
                  onClick={() => setModalActiveFlg(true)}
                />
                <SaveButton
                  icon={<></>}
                  className={style.button}
                  variant="contained"
                  label="保存"
                  alwaysEnable
                />
              </Stack>
            </CustomToolbar>
            <ContentsTitle title="基本情報" />
            <MuiTable>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>氏名</TableCell>
                <TableCell className={style.tableRowData}>
                  <TextInput
                    variant="outlined"
                    label="名前"
                    source="name"
                    helperText={false}
                    fullWidth
                    validate={nameValidation}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>
                  ステータス
                </TableCell>
                <TableCell className={style.tableRowData}>
                  <FormControl>
                    <ReferenceInput source="status.id" reference="users/status">
                      <SelectInput
                        variant="outlined"
                        className={style.selectBox}
                        formClassName={style.selectInput}
                        label="ステータス"
                        optionText="name"
                        optionValue="id"
                        helperText={false}
                        validate={required()}
                      />
                    </ReferenceInput>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>ID</TableCell>
                <TableCell
                  className={`${style.tableRowData} ${style.disabled}`}
                >
                  {user.login_id}
                </TableCell>
              </TableRow>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>権限</TableCell>
                <TableCell
                  className={`${style.tableRowData} ${style.disabled}`}
                >
                  {user.permission.name}
                </TableCell>
              </TableRow>
            </MuiTable>
            <Box>
              <ContentsTitle title="アカウントメモ" />
              <TextInput
                className={style.note}
                multiline
                fullWidth
                label={false}
                helperText={false}
                source="internal_note"
              />
            </Box>
            <Stack
              direction="row"
              spacing={2.5}
              alignItems="center"
              className={style.container}
            >
              <MuiButton
                text="キャンセル"
                color="reverse"
                onClick={() => setModalActiveFlg(true)}
              />
              <SaveButton
                icon={<></>}
                className={style.button}
                variant="contained"
                label="保存"
                alwaysEnable
              />
            </Stack>
          </Form>
        ) : (
          <Loading />
        )}
      </Edit>
      {modalActiveFlg && (
        <ConfirmModal
          open={modalActiveFlg}
          setOpen={changeModalActiveFlg}
          title="編集内容を破棄しますか？"
          applyText="破棄"
          onClickApply={handleModalApply}
          cancelText="キャンセル"
          onClickCancel={handleModalCancel}
        />
      )}
    </>
  )
}
