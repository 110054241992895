import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  TableRow,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  Table,
  Stack,
  Button,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Link } from 'react-admin'

import { apiProvider } from '../../../../provider/apiProvider'
import {
  ReportInterview,
  Response,
} from '../../../../provider/types/ReportInterview'
import { filterDateTimeUtil } from '../../../../Util/dateUtil'

import style from './style.module.css'

export const InterviewReport = () => {
  const [year, setYear] = useState(new Date().getFullYear())
  const [data, setData] = useState<ReportInterview[]>([])

  useEffect(() => {
    apiProvider.getInterviewReports({ id: year }).then((response: Response) => {
      setData(response.data.month_list)
    })
  }, [year])

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        className={style.container}
      >
        <Button
          variant="text"
          className={style.sideButton}
          color="inherit"
          onClick={() => setYear(year - 1)}
        >
          {year - 1}
        </Button>
        <Button onClick={() => setYear(year - 1)}>
          <ArrowBackIosIcon className={style.icon} />
        </Button>
        <Button
          variant="text"
          color="inherit"
          className={style.year}
          onClick={() => setYear(year)}
        >
          {year}
        </Button>
        <Button onClick={() => setYear(year + 1)}>
          <ArrowForwardIosIcon className={style.icon} />
        </Button>
        <Button
          variant="text"
          className={style.sideButton}
          onClick={() => setYear(year + 1)}
        >
          {year + 1}
        </Button>
      </Stack>
      <TableContainer className={style.table}>
        <Table>
          <TableHead className={style.title}>
            <TableRow>
              <TableCell align="center">月</TableCell>
              <TableCell align="center">面談設置数</TableCell>
              <TableCell align="center">面談成立数</TableCell>
              <TableCell align="center">面談成立率</TableCell>
              <TableCell align="center">契約率</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={style.body}>
            {data.map((value: ReportInterview, key: number) => {
              return (
                <TableRow key={key}>
                  <TableCell align="right">{value.month + '月'}</TableCell>
                  <TableCell align="right">
                    {value.setupCount ? (
                      <Link
                        to={`/admin/interviews?filter=${JSON.stringify({
                          period_select: 'period',
                          start_date: filterDateTimeUtil(
                            new Date(year, value.month - 1, 1),
                          ),
                          end_date: filterDateTimeUtil(
                            new Date(year, value.month, 0, 23, 59),
                          ),
                          status: null,
                        })}`}
                        color="link"
                      >
                        {Math.round(value.setupCount)}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {value.completeCount ? (
                      <Link
                        to={`/admin/interviews?filter=${JSON.stringify({
                          period_select: 'period',
                          start_date: filterDateTimeUtil(
                            new Date(year, value.month - 1, 1),
                          ),
                          end_date: filterDateTimeUtil(
                            new Date(year, value.month, 0, 23, 59),
                          ),
                          status: 2,
                        })}`}
                        color="link"
                      >
                        {Math.round(value.completeCount)}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {value.completeRate
                      ? Math.round(value.completeRate) + '%'
                      : '-'}
                  </TableCell>
                  <TableCell align="right">
                    {value.contractRate
                      ? Math.round(value.contractRate) + '%'
                      : '-'}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
