import { ReferenceInput, SelectInput, TextInput, Filter } from 'react-admin'

export const DetectiveListFilters = () => {
  return (
    <Filter>
      <TextInput
        label="電話番号で検索"
        source="phone_number"
        variant="outlined"
        resettable
        alwaysOn
      />

      <TextInput
        label="ワードで検索"
        source="word"
        variant="outlined"
        resettable
        alwaysOn
      />

      <ReferenceInput reference="detectives/status" source="status" alwaysOn>
        <SelectInput
          label="ステータス"
          emptyText="選択を解除する"
          emptyValue="null"
          optionText="name"
          variant="outlined"
          parse={(value: string) => (value === 'null' ? null : value)}
        />
      </ReferenceInput>

      <ReferenceInput reference="prefecture" source="prefecture" alwaysOn>
        <SelectInput
          label="都道府県"
          emptyText="選択を解除する"
          emptyValue="null"
          optionText="name"
          variant="outlined"
          parse={(value: string) => (value === 'null' ? null : value)}
        />
      </ReferenceInput>
    </Filter>
  )
}
