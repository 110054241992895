import {
  Datagrid,
  FunctionField,
  List,
  ShowButton,
  TextField,
  usePermissions,
  useRedirect,
} from 'react-admin'

import { Prefecture } from '../../../../provider/types/prefecture'
import { FormatUtil } from '../../../../Util/formatUtil'
import { MuiButton } from '../../../atom/MuiButton'
import { StatusText } from '../../../atom/StatusText'
import { DetectiveListFilters } from '../../../feature/Detective/DetectiveListFilters'
import { CustomToolbar } from '../../../organism/CustomToolbar'

import './global.css'
import style from './style.module.css'

export const DetectiveList = () => {
  const { permissions } = usePermissions()
  const redirect = useRedirect()
  return (
    <>
      <List actions={false}>
        <CustomToolbar history={['探偵社一覧']}>
          {permissions === 'admin' && (
            <MuiButton
              text="探偵社の新規追加"
              onClick={() => redirect('/admin/detectives/create')}
            />
          )}
        </CustomToolbar>
        <DetectiveListFilters />
        <Datagrid bulkActionButtons={false} className="list">
          <TextField
            label={'探偵社'}
            source="company.name"
            sortBy="company_name"
          />
          <FunctionField
            label={'ステータス'}
            source="status"
            render={(record: { status: { id: number; name: string } }) => (
              <StatusText status={record.status} />
            )}
          />
          <TextField
            label={'担当者'}
            source="representative.name"
            sortBy="representative_name_kana"
          />
          <FunctionField
            label={'電話番号'}
            source="phone_number"
            render={(record: { phone_number: string }) =>
              FormatUtil.formatTelNumber(record.phone_number)
            }
          />
          <FunctionField
            label={'住所'}
            render={(record: { prefecture: Prefecture; address: string }) =>
              `${record.prefecture.name}${record.address}`
            }
          />
          <ShowButton
            className={style.showButton}
            startIcon={null}
            label={'詳細'}
          />
        </Datagrid>
      </List>
    </>
  )
}
