import CloseIcon from '@mui/icons-material/Close'
import { Box, Button } from '@mui/material'
import { useState, ChangeEvent } from 'react'
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  Filter,
  RaRecord,
  DateTimeInput,
  AutocompleteArrayInput,
  useListContext,
} from 'react-admin'

export const InterviewsListFilters = () => {
  const [periodSelected, setPeriodSelected] = useState<
    'today' | 'period' | null
  >(() => {
    const listFilters = localStorage.getItem('RaStore.interviews.listParams')
    if (listFilters) {
      const listFiltersJson = JSON.parse(listFilters)
      console.log(listFiltersJson)
      return listFiltersJson.filter?.period_select
    } else {
      return 'today'
    }
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement> | RaRecord) => {
    setPeriodSelected(event.target.value)
  }

  const { setFilters, displayedFilters, filterValues } = useListContext()

  const handleClear = () => {
    setPeriodSelected(null)
    setFilters({}, displayedFilters)
  }

  const isFilterApplied = () => {
    return Object.keys(filterValues).length > 0
  }

  return (
    <>
      <Filter>
        <TextInput
          label="電話番号で検索"
          source="phone_number"
          variant="outlined"
          resettable
          alwaysOn
        />
        <TextInput
          label="ワードで検索"
          source="word"
          variant="outlined"
          resettable
          alwaysOn
        />

        <ReferenceInput reference="interviews/status" source="status" alwaysOn>
          <AutocompleteArrayInput
            variant="outlined"
            label="ステータス"
            optionText="name"
            optionValue="id"
          />
        </ReferenceInput>
        <ReferenceInput reference="users/active" source="staff_id" alwaysOn>
          <SelectInput
            label="担当者"
            emptyText="選択を解除する"
            emptyValue="null"
            optionText="name"
            variant="outlined"
            parse={(value: string) => (value === 'null' ? null : value)}
          />
        </ReferenceInput>
        <ReferenceInput reference="detectives" source="detective" alwaysOn>
          <SelectInput
            label="探偵社"
            emptyText="選択を解除する"
            emptyValue="null"
            optionValue="id"
            optionText="company.name"
            variant="outlined"
            parse={(value: string) => (value === 'null' ? null : value)}
          />
        </ReferenceInput>
      </Filter>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Filter>
          <SelectInput
            source="period_select"
            label="面談日時"
            emptyText="選択を解除する"
            emptyValue="null"
            variant="outlined"
            value={periodSelected}
            choices={[
              { id: 'today', name: '今日' },
              { id: 'period', name: '期間を指定' },
            ]}
            onChange={handleChange}
            alwaysOn
            parse={(value: string) => (value === 'null' ? null : value)}
          />
          {'today' === periodSelected && (
            <ReferenceInput
              source="desired_call_time.id"
              reference="applications/calltimes"
              alwaysOn
            >
              <SelectInput
                label="面談日時"
                emptyText="選択を解除する"
                emptyValue="null"
                variant="outlined"
                optionText="range_time"
                parse={(value: string) => (value === 'null' ? null : value)}
              />
            </ReferenceInput>
          )}
          {'period' === periodSelected && (
            <DateTimeInput
              source="start_date"
              label="日時"
              variant="outlined"
              alwaysOn
            />
          )}
          {'period' === periodSelected && (
            <DateTimeInput
              source="end_date"
              label="日時"
              variant="outlined"
              alwaysOn
            />
          )}
        </Filter>
        <Button
          onClick={handleClear}
          variant="text"
          sx={{
            fontSize: '12px',
            width: '220px',
            background: '#ffffff',
            border: '1px solid #e0e0e0',
          }}
          disabled={!isFilterApplied()}
        >
          <CloseIcon
            color={!isFilterApplied() ? 'disabled' : 'primary'}
            sx={{ mr: 1 }}
          />
          検索条件をクリアする
        </Button>
      </Box>
    </>
  )
}
