import { ReferenceInput, SelectInput, Filter, DateTimeInput } from 'react-admin'

export const ActivityLogListFilters = () => {
  return (
    <Filter>
      <DateTimeInput
        source="start_date"
        label="日時"
        variant="outlined"
        alwaysOn
      />
      <DateTimeInput
        source="end_date"
        label="日時"
        variant="outlined"
        alwaysOn
      />
      <ReferenceInput reference="users/active" source="operated_by" alwaysOn>
        <SelectInput
          label="担当者"
          emptyText="選択を解除する"
          emptyValue="null"
          optionText="name"
          variant="outlined"
          parse={(value: string) => (value === 'null' ? null : value)}
        />
      </ReferenceInput>
    </Filter>
  )
}
