import { TableContainer, Table, TableBody } from '@mui/material'

import style from './style.module.css'
import { Props } from './type'

export const MuiTable = ({ children }: Props) => {
  return (
    <TableContainer className={style.infoTable}>
      <Table>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}
