import { Box, TableCell, TableRow, Stack, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  Edit,
  Form,
  ReferenceInput,
  SelectInput,
  TextInput,
  email,
  required,
  useGetRecordId,
  useRedirect,
} from 'react-admin'

import { apiProvider } from '../../../../provider/apiProvider'
import { Detective, DefaultValue } from '../../../../provider/types/detective'
import { ValidateForm } from '../../../../provider/validate/detective'
import { ContentsTitle } from '../../../atom/ContentsTitle'
import { MuiButton } from '../../../atom/MuiButton'
import { ConfirmModal } from '../../../organism/ConfirmModal'
import { CustomToolbar } from '../../../organism/CustomToolbar'
import { ErrorModal } from '../../../organism/ErrorModal'
import { MuiTable } from '../../../organism/MuiTable'

import style from './style.module.css'
import './global.css'

export const DetectiveEdit = () => {
  const recordId = useGetRecordId()
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [editError, setEditError] = useState(false)
  const redirect = useRedirect()
  const [detective, setDetective] = useState<Detective>(DefaultValue)
  const [modalActiveFlg, setModalActiveFlg] = useState(false)

  // 電話番号バリデーション
  const fullWidthNumberChange = (target: string) => {
    target.replace(/[\uFF10-\uFF19]/g, (number) => {
      return String.fromCharCode(number.charCodeAt(0) - 0xfee0)
    })
  }

  const validatePhoneNumber = (value: string) => {
    const pattern = /^[0-9]{10,11}$/ // 10桁の電話番号を想定
    const phoneInput = value.replace(/[- ]/g, '')
    fullWidthNumberChange(phoneInput)
    return pattern.test(phoneInput)
      ? undefined
      : '電話番号の記入に誤りがあります。'
  }

  useEffect(() => {
    if (recordId) {
      apiProvider.getOne('detectives', { id: recordId }).then((response) => {
        setIsLoading(true)
        setDetective(response.data)
        if (response.data.representative.kana.length > 0) {
          setIsDisabled(false)
        }
      })
    }
  }, [recordId])

  const changeModalActiveFlg = () => {
    setModalActiveFlg(!modalActiveFlg)
  }

  function handleModalApply() {
    redirect(`/admin/detectives/${detective.id}/show`)
  }

  function handleModalCancel() {
    setModalActiveFlg(false)
  }

  return isLoading ? (
    editError ? (
      <ErrorModal />
    ) : (
      <>
        <Edit
          className={style.edit}
          actions={false}
          redirect={'show'}
          mutationOptions={{
            onError: () => {
              setEditError(true)
            },
          }}
          mutationMode="pessimistic"
        >
          <Form
            validate={ValidateForm}
            reValidateMode="onBlur"
            sanitizeEmptyValues
            className="form"
            warnWhenUnsavedChanges
          >
            <CustomToolbar
              history={['探偵社一覧', `${detective.company.name}`]}
            >
              <Stack direction="row" spacing={2.5} alignItems="center">
                <MuiButton
                  text="キャンセル"
                  color="reverse"
                  onClick={() => setModalActiveFlg(true)}
                />
                <Button
                  className={style.button}
                  type="submit"
                  disabled={isDisabled}
                  variant="contained"
                >
                  保存
                </Button>
              </Stack>
            </CustomToolbar>
            <ContentsTitle title="基本情報" />
            <MuiTable>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>探偵社名</TableCell>
                <TableCell className={style.tableRowData}>
                  <TextInput
                    onChange={(e) => {
                      e.target.value === ''
                        ? setIsDisabled(true)
                        : setIsDisabled(false)
                    }}
                    variant="outlined"
                    label="探偵社名"
                    source="company.name"
                    helperText={false}
                    validate={required()}
                    fullWidth
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>ふりがな</TableCell>
                <TableCell className={style.tableRowData}>
                  <TextInput
                    variant="outlined"
                    label="ふりがな"
                    source="company.kana"
                    helperText={false}
                    fullWidth
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>
                  ステータス
                </TableCell>
                <TableCell className={style.tableRowData}>
                  <ReferenceInput
                    source="status.id"
                    reference="detectives/status"
                  >
                    <SelectInput
                      variant="outlined"
                      label="ステータス"
                      optionText="name"
                      optionValue="id"
                      helperText={false}
                    />
                  </ReferenceInput>
                </TableCell>
              </TableRow>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>担当者</TableCell>
                <TableCell className={style.tableRowData}>
                  <TextInput
                    variant="outlined"
                    label="担当者"
                    source="representative.name"
                    helperText={false}
                    validate={required()}
                    fullWidth
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>ふりがな</TableCell>
                <TableCell className={style.tableRowData}>
                  <TextInput
                    variant="outlined"
                    label="ふりがな"
                    source="representative.kana"
                    helperText={false}
                    fullWidth
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>電話番号</TableCell>
                <TableCell className={style.tableRowData}>
                  <TextInput
                    variant="outlined"
                    label="電話番号"
                    source="phone_number"
                    helperText={false}
                    validate={[required(), validatePhoneNumber]}
                    fullWidth
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>
                  メールアドレス
                </TableCell>
                <TableCell className={style.tableRowData}>
                  <TextInput
                    variant="outlined"
                    label="メールアドレス"
                    source="email"
                    helperText={false}
                    validate={[required(), email()]}
                    fullWidth
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>都道府県</TableCell>
                <TableCell className={`${style.tableRowData}`}>
                  <ReferenceInput source="prefecture.id" reference="prefecture">
                    <SelectInput
                      variant="outlined"
                      className={style.selectBox}
                      formClassName={style.selectInput}
                      label="都道府県"
                      helperText={false}
                      optionText="name"
                      optionValue="id"
                    />
                  </ReferenceInput>
                </TableCell>
              </TableRow>
              <TableRow className={style.tableRow}>
                <TableCell className={style.tableRowTitle}>住所</TableCell>
                <TableCell className={style.tableRowData}>
                  <TextInput
                    variant="outlined"
                    label="住所"
                    source="address"
                    helperText={false}
                    validate={required()}
                    fullWidth
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </MuiTable>
            <Box>
              <ContentsTitle title="探偵社メモ" />
              <TextInput
                variant="outlined"
                label="探偵社メモ"
                className={style.note}
                multiline
                fullWidth
                helperText={false}
                source="internal_note"
                inputProps={{ maxLength: 1000 }}
              />
            </Box>
            <Stack gap={2.5} direction="row" className={style.container}>
              <MuiButton
                text="キャンセル"
                color="reverse"
                onClick={() => setModalActiveFlg(true)}
              />
              <Button
                className={style.button}
                type="submit"
                disabled={isDisabled}
                variant="contained"
              >
                保存
              </Button>
            </Stack>
          </Form>
        </Edit>
        {modalActiveFlg && (
          <ConfirmModal
            open={modalActiveFlg}
            setOpen={changeModalActiveFlg}
            title="編集内容を破棄しますか？"
            applyText="破棄"
            onClickApply={handleModalApply}
            cancelText="キャンセル"
            onClickCancel={handleModalCancel}
          />
        )}
      </>
    )
  ) : (
    <></>
  )
}
