import { Typography } from '@mui/material'

import style from './style.module.css'
import { Props } from './type'

export const StatusText = ({ status }: Props) => {
  return status.id === 0 ? (
    <Typography className={style.statusText} color="status.invalid">
      {status.name}
    </Typography>
  ) : (
    <Typography className={style.statusText} color="status.valid">
      {status.name}
    </Typography>
  )
}
