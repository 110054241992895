import { Typography, Box } from '@mui/material'

import { MuiButton } from '../../atom/MuiButton'
import { MuiModal } from '../MuiModal'

import styles from './style.module.css'

export const ConfirmModal = ({
  open,
  setOpen,
  title,
  content,
  onClickApply,
  onClickCancel,
  applyText,
  cancelText,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  title?: string
  content?: string
  onClickApply: () => void
  onClickCancel: () => void
  applyText: string
  cancelText: string
}) => {
  return (
    <MuiModal open={open} closeModal={() => setOpen(false)}>
      <Box>
        {title && (
          <Typography variant="h6" gutterBottom sx={{ mb: 4 , textAlign: 'center'}}>
            {title}
          </Typography>
        )}
        {content && (
          <Typography variant="body1" gutterBottom sx={{ mb: 4 , textAlign: 'center'}}>
            {content}
          </Typography>
        )}
        <Box className={styles.modalButtonContainer}>
          <MuiButton
            onClick={onClickCancel}
            text={cancelText}
            color="reverse"
          />
          <MuiButton onClick={onClickApply} text={applyText} />
        </Box>
      </Box>
    </MuiModal>
  )
}
