import { Datagrid, List, TextField } from 'react-admin'

import { ActivityLogListFilters } from '../../../feature/ActivityLog/ActivityLogListFilters'
import { CustomToolbar } from '../../../organism/CustomToolbar'

import './global.css'

export const ActivityLogList = () => {
  return (
    <List actions={false}>
      <CustomToolbar history={['操作ログ']}></CustomToolbar>

      <ActivityLogListFilters />

      <Datagrid bulkActionButtons={false} className="list">
        <TextField label="日時" source="created_at" />
        <TextField label="ユーザ" source="operator_name" />
        <TextField label="操作対象" source="target_name" />
        <TextField label="アクション" source="action_name" />
      </Datagrid>
    </List>
  )
}
