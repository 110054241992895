import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { TopToolbar } from 'react-admin'

import { MuiBreadCrumbs } from '../../organism/MuiBreadCrumbs'
import { Props } from '../../organism/MuiBreadCrumbs/type'

import style from './style.module.css'

export const CustomToolbar = ({
  children,
  history,
}: {
  children?: ReactNode
} & Props) => {
  return (
    <TopToolbar className={style.topToolBar}>
      <MuiBreadCrumbs history={history} />
      <Box className={style.buttonContainer}>{children}</Box>
    </TopToolbar>
  )
}
