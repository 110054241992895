import { Breadcrumbs, Link } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import style from './style.module.css'
import { Props } from './type'

export const MuiBreadCrumbs = ({ history }: Props) => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)

  return (
    <Breadcrumbs separator=">>" aria-label="breadcrumb">
      {history.map((page, index) => {
        // /admin/ をベースとしてリンクを作成するため -> index + 2
        const routeTo = `/${pathnames.slice(0, index + 2).join('/')}`
        const lastRouteTo = `/${pathnames.join('/')}`
        const isLast = index === history.length - 1

        return (
          <Link
            underline="hover"
            key={index}
            component={RouterLink}
            to={isLast ? lastRouteTo : routeTo}
            {...(isLast && {
              className: style.currentPage,
            })}
          >
            {page}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}
