import { Typography } from '@mui/material'

import style from './style.module.css'
import { Props } from './type'

export const InterviewStatus = ({ status, interviewDate }: Props) => {
  const today = new Date()
  const interviewD = interviewDate ? new Date(interviewDate) : ''
  let diffDay = 0
  if (interviewD) {
    diffDay = Math.floor((interviewD.getTime() - today.getTime()) / 86400000)
  }

  const component = () => {
    switch (status.id) {
      case 0:
        return (
          <Typography className={style.statusText} color="status.initial">
            {status.name}
          </Typography>
        )
        break
      case 1:
        if (diffDay < 0) {
          return (
            <Typography className={style.statusText} color="status.pending">
              {status.name}
            </Typography>
          )
        } else {
          return (
            <Typography className={style.statusText} color="status.initial">
              {status.name}
            </Typography>
          )
        }
        break
      case 2:
        return (
          <Typography className={style.statusText} color="status.valid">
            {status.name}
          </Typography>
        )
        break
      case 3:
        return (
          <Typography className={style.statusText} color="status.cancel">
            {status.name}
          </Typography>
        )
        break
    }
  }
  return <>{component()}</>
}
