import BusinessIcon from '@mui/icons-material/Business'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { default as GroupIcon } from '@mui/icons-material/Group'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import { createTheme } from '@mui/material/styles'
import {
  CustomRoutes,
  defaultTheme,
  Resource,
  Admin as Store,
} from 'react-admin'
import { Route } from 'react-router-dom'

import { ActivityLogList } from './components/template/ActivityLog/List'
import { ApplicationCreate } from './components/template/Application/Create'
import { ApplicationEdit } from './components/template/Application/Edit'
import { ApplicationList } from './components/template/Application/List'
import { ApplicationShow } from './components/template/Application/Show'
import {
  DetectiveCreate,
  DetectiveEdit,
  DetectiveList,
  DetectiveShow,
  DetectiveOperatorEdit,
} from './components/template/Detective'
import {
  InterviewList,
  InterviewShow,
  InterviewEdit,
} from './components/template/Interview'
import { Login } from './components/template/Login'
import { MyPageShow } from './components/template/MyPage'
import { Report } from './components/template/Report/Report'
import { UserEdit, UserList, UserShow } from './components/template/User'
import { Layout } from './Layout'
import { apiProvider } from './provider/apiProvider'
import { authProvider } from './provider/authProvider'
import { i18nProvider } from './provider/i18nProvider'

import './Admin.css'

declare module '@mui/material/styles' {
  interface Palette {
    reverse: Palette['primary']
  }

  interface PaletteOptions {
    reverse?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    reverse: true
  }
}

const theme = createTheme(defaultTheme, {
  palette: {
    primary: {
      light: '#757ce8',
      main: '#457670',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#000000',
      contrastText: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#457670',
    },
    background: {
      paper: '#FFF',
      default: '#F5F5F5',
      none: 'none',
    },
    reverse: {
      main: '#fff',
      contrastText: '#457670',
    },
    status: {
      valid: '#457670',
      pending: '#FF8A48',
      invalid: '#FF7171',
      cancel: '#606060',
      initial: '#D9D9D9',
      alert: '#FF8A48',
    },
    link: '#3076ff',
  },
})

export const Admin = () => {
  const canViewPosts = 'admin' === localStorage.getItem('permissions')

  return (
    <Store
      basename="/admin"
      theme={theme}
      authProvider={authProvider}
      dataProvider={apiProvider}
      i18nProvider={i18nProvider}
      loginPage={Login}
      layout={Layout}
      requireAuth
    >
      <Resource
        name="applications"
        create={ApplicationCreate}
        list={ApplicationList}
        show={ApplicationShow}
        edit={ApplicationEdit}
        options={{ label: '申し込み一覧' }}
        icon={FormatListBulletedIcon}
      />
      <Resource
        name="interviews"
        list={InterviewList}
        show={InterviewShow}
        edit={InterviewEdit}
        options={{ label: '面談一覧' }}
        icon={MeetingRoomIcon}
      />
      {canViewPosts && (
        <Resource
          name="users"
          list={UserList}
          show={UserShow}
          edit={UserEdit}
          options={{ label: 'アカウント一覧' }}
          icon={GroupIcon}
        />
      )}
      <Resource
        name="detectives"
        list={DetectiveList}
        create={DetectiveCreate}
        show={DetectiveShow}
        edit={canViewPosts ? DetectiveEdit : DetectiveOperatorEdit}
        options={{ label: '探偵社一覧' }}
        icon={BusinessIcon}
      />
      <Resource
        name="reports"
        options={{ label: 'レポート' }}
        icon={InsertChartIcon}
        list={Report}
      />
      {canViewPosts && (
        <Resource
          intent="registration"
          name="activitylog"
          list={ActivityLogList}
          options={{ label: '操作ログ' }}
          icon={GroupIcon}
        />
      )}
      <CustomRoutes>
        <Route path="profile" element={<MyPageShow />} />
      </CustomRoutes>
    </Store>
  )
}
