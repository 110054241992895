import { Typography } from '@mui/material'

import style from './style.module.css'
import { Props } from './type'

export const MuiPre = ({ children }: Props) => {
  return (
    <Typography component="pre" className={style.pre}>
      {children}
    </Typography>
  )
}
