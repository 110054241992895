import { SvgIcon } from '@mui/material'

import style from './style.module.css'

export const MemoPatch = () => {
  return (
    <SvgIcon className={style.icon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13px"
        height="13px"
        viewBox="0 0 13 13"
        fill="none"
      >
        <circle cx="6.5" cy="6.5" r="4.5" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.08333 6.49992C1.08333 3.50829 3.50837 1.08325 6.49999 1.08325C9.49162 1.08325 11.9167 3.50829 11.9167 6.49992C11.9167 9.49154 9.49162 11.9166 6.49999 11.9166C3.50837 11.9166 1.08333 9.49154 1.08333 6.49992ZM6.49999 7.04158C6.35634 7.04158 6.21856 6.98452 6.11698 6.88293C6.0154 6.78135 5.95833 6.64358 5.95833 6.49992V4.33325C5.95833 4.18959 6.0154 4.05182 6.11698 3.95024C6.21856 3.84865 6.35634 3.79158 6.49999 3.79158C6.64365 3.79158 6.78143 3.84865 6.88301 3.95024C6.98459 4.05182 7.04166 4.18959 7.04166 4.33325V6.49992C7.04166 6.64358 6.98459 6.78135 6.88301 6.88293C6.78143 6.98452 6.64365 7.04158 6.49999 7.04158ZM5.68749 8.66658C5.68749 8.4511 5.7731 8.24443 5.92547 8.09206C6.07784 7.93969 6.28451 7.85408 6.49999 7.85408H6.50541C6.7209 7.85408 6.92756 7.93969 7.07994 8.09206C7.23231 8.24443 7.31791 8.4511 7.31791 8.66658V8.672C7.31791 8.88749 7.23231 9.09415 7.07994 9.24653C6.92756 9.3989 6.7209 9.4845 6.50541 9.4845H6.49999C6.28451 9.4845 6.07784 9.3989 5.92547 9.24653C5.7731 9.09415 5.68749 8.88749 5.68749 8.672V8.66658Z"
          fill="#FF8A48"
        />
      </svg>
    </SvgIcon>
  )
}
