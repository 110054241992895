import { ReferenceInput, SelectInput, TextInput, Filter } from 'react-admin'

export const UserListFilters = () => {
  return (
    <Filter>
      <TextInput
        label="ワードで検索"
        source="word"
        variant="outlined"
        resettable
        alwaysOn
      />
      <ReferenceInput reference="users/status" source="status" alwaysOn>
        <SelectInput
          label="ステータス"
          emptyText="選択を解除する"
          emptyValue="null"
          optionText="name"
          variant="outlined"
          parse={(value: string) => (value === 'null' ? null : value)}
        />
      </ReferenceInput>
      <ReferenceInput
        reference="users/permission"
        source="permissions"
        alwaysOn
      >
        <SelectInput
          label="権限"
          emptyText="選択を解除する"
          emptyValue="null"
          optionText="name"
          variant="outlined"
          parse={(value: string) => (value === 'null' ? null : value)}
        />
      </ReferenceInput>
    </Filter>
  )
}
