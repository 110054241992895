import AccountBox from '@mui/icons-material/AccountBox'
import { Menu, MenuItemLink } from 'react-admin'

export const MyMenu = () => (
  <Menu>
    <Menu.ResourceItem name="applications" />
    <Menu.ResourceItem name="interviews" />
    <Menu.ResourceItem name="users" />
    <Menu.ResourceItem name="detectives" />
    <Menu.ResourceItem name="reports" />
    <MenuItemLink
      to="/admin/profile"
      primaryText="マイページ"
      leftIcon={<AccountBox />}
      placeholder=""
    />
  </Menu>
)
