import axios, { AxiosError, AxiosHeaders } from 'axios'
import { AuthProvider, UserIdentity } from 'react-admin'

interface LoginPayload {
  login_id: string
  password: string
}

const baseURL = process.env.REACT_APP_PUBLIC_API_ENDPOINT

// https://marmelab.com/react-admin/doc/3.19/Authentication.html
export const authProvider: AuthProvider = {
  // send username and password to the auth server and get back credentials
  login: async ({ login_id, password }: LoginPayload): Promise<void> => {
    console.log('authProvider.login()')
    await axios.get('/sanctum/csrf-cookie', {
      baseURL: baseURL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withXSRFToken: true,
      withCredentials: true,
    })

    return await axios
      .post(
        '/v1/login',
        { login_id, password },
        {
          baseURL: baseURL,
          headers: new AxiosHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }),
          withXSRFToken: true,
          withCredentials: true,
        },
      )
      .then((res) => {
        localStorage.setItem('token', res.data.access_token)
        localStorage.setItem('permissions', res.data.permission)
        window.location.href = '/admin'
      })
      .catch((error) => {
        console.log(`Network error : ${error.message}`)
        return Promise.reject(error)
      })
  },
  // when the dataProvider returns an error, check if this is an authentication error
  checkError: (error: AxiosError) => {
    console.log(error)
    const status = error.response ? error.response.status : 500
    if (status === 401 || status === 403) {
      return Promise.reject({ redirectTo: '/login' })
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve()
  },
  // when the user navigates, make sure that their credentials are still valid
  checkAuth: async (): Promise<void> => {
    console.log('authProvider.checkAuth()')
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject(new Error('Not logged in'))
  },
  // remove local credentials and notify the auth server that the user logged out
  logout: async (): Promise<string | false | void> => {
    console.log('authProvider.logout()')
    return axios
      .get('/v1/logout', {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
      })
      .then((): Promise<string> => {
        localStorage.removeItem('token')
        localStorage.removeItem('permissions')
        return Promise.resolve('/login')
      })
      .catch((error: AxiosError): Promise<string | false> => {
        const status = error.response ? error.response.status : 500
        if (status == 401 || status == 403) {
          localStorage.removeItem('token')
          localStorage.removeItem('permissions')
          return Promise.resolve('/login')
        }
        console.log(`Network error : ${error.message}`)
        return Promise.reject(error)
      })
  },
  // get the user's profile
  getIdentity: async (): Promise<UserIdentity> => {
    console.log('authProvider.getIdentity()')
    return await axios
      .get('/v1/profile', {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
        withCredentials: true,
      })
      .then((res) => {
        localStorage.setItem('permissions', res.data.data.permission.key)
        return { id: res.data.data.id, fullName: res.data.data.name }
      })
      .catch((error) => {
        console.log(`Network error : ${error.message}`)
        return Promise.reject(error)
      })
  },
  // get the user permissions (optional)
  getPermissions: async (): Promise<string | void> => {
    const role = localStorage.getItem('permissions')
    return role ? Promise.resolve(role) : Promise.reject()
  },
}
