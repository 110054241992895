import { Application } from './application'
import { Detective } from './detective'

export type Interview = {
  id: number
  interview_date: string
  location: string
  status: Status
  result: Result
  application: Application
  detective: Detective
  internal_note: string | null
  note: string | null
}

export type Status = {
  id: number
  name: string
}

export type Result = {
  id: number
  name: string
}

export const DefaultValue: Interview = {
  id: 0,
  interview_date: '',
  location: '',
  status: {
    id: 0,
    name: '',
  },
  result: {
    id: 0,
    name: '',
  },
  application: {
    id: 0,
    application_date: '',
    customer: {
      name: '',
      kana: '',
    },
    age_group: {
      id: 10,
      name: '',
      from: '',
      to: '',
    },
    gender: {
      id: 0,
      name: '',
    },
    email: '',
    phone_number: '',
    desired_call: {
      id: 0,
      date: '',
      range_time: '',
      from_time: '',
      to_time: '',
    },
    status: {
      id: 0,
      name: '',
    },
    prefecture: {
      id: 1,
      name: '',
    },
    note: '',
    staff: null,
    hearing: {
      budget: '',
      target: {
        name: '',
        relation: '',
      },
      research_type: {
        id: 0,
        name: '',
      },
      order_accuracy: {
        id: 0,
        name: '',
      },
      detail: '',
      interview_detail: '',
      important_point: {
        id: 0,
        name: '',
      },
    },
  },
  detective: {
    id: 0,
    company: {
      name: '',
      kana: '',
    },
    status: {
      id: 0,
      name: '',
    },
    representative: {
      name: '',
      kana: '',
    },
    phone_number: '',
    email: '',
    prefecture: {
      id: 1,
      name: '',
    },
    address: '',
    internal_note: null,
  },
  internal_note: null,
  note: null,
}

export type SendInterviewData = {
  application_id: number
  status: number
  result: number
  detective_id: number
  interview_data: string
  location: string
  internal_note: string | null
  note: string | null
}

export const SendInterviewDataDefault: SendInterviewData[] = [
  {
    application_id: 0,
    status: 0,
    result: 0,
    detective_id: 0,
    interview_data: '',
    location: '',
    internal_note: null,
    note: null,
  },
]

export type InterviewFormProps = {
  index: number
}

export type Errors = {
  interview_date: boolean
  detective_id: boolean
}

export const ErrorsDefault: Errors = {
  interview_date: false,
  detective_id: false,
}
