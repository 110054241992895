import { Prefecture } from './prefecture'

export type Detective = {
  id: number | string
  company: Company
  status: Status
  representative: Representative
  phone_number: string
  email: string
  prefecture: Prefecture
  address: string
  internal_note: string | null
}

export type Company = {
  name: string
  kana: string
}

export type Status = {
  id: number
  name: string
}

export type Representative = {
  name: string
  kana: string
}

export const DefaultValue = {
  id: 0,
  company: {
    name: '',
    kana: '',
  },
  status: {
    id: 0,
    name: '',
  },
  representative: {
    name: '',
    kana: '',
  },
  phone_number: '',
  email: '',
  prefecture: {
    id: 1,
    name: '',
  },
  address: '',
  internal_note: null,
}
