import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  TableRow,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  Table,
  Stack,
  Button,
  Typography,
  Grid,
  Box,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Loading, Link } from 'react-admin'

import { apiProvider } from '../../../../provider/apiProvider'
import {
  ReportManagement,
  ResDay,
  ResManagement,
  ResMonth,
} from '../../../../provider/types/ReportInterview'
import { filterDateTimeUtil } from '../../../../Util/dateUtil'

import style from './style.module.css'

export const ManagementReport = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [data, setData] = useState<ReportManagement>({
    year_list: [],
    month_list: [],
    day_list: [],
  })

  useEffect(() => {
    setIsLoading(true)
    apiProvider
      .getManagementReports({ id: year, meta: { year: year, month: month } })
      .then((res: ResManagement) => {
        setData(Array.isArray(res.data) ? res.data[0] : res.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [year, month])

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        className={style.btnContainer}
      >
        <Button
          variant="text"
          className={style.sideButton}
          color="inherit"
          onClick={() => setYear(year - 1)}
        >
          {year - 1}
        </Button>
        <Button onClick={() => setYear(year - 1)}>
          <ArrowBackIosIcon className={style.icon} />
        </Button>
        <Button
          variant="text"
          color="inherit"
          className={style.year}
          onClick={() => setYear(year)}
        >
          {year}
        </Button>
        <Button onClick={() => setYear(year + 1)}>
          <ArrowForwardIosIcon className={style.icon} />
        </Button>
        <Button
          variant="text"
          className={style.sideButton}
          onClick={() => setYear(year + 1)}
        >
          {year + 1}
        </Button>
      </Stack>
      <Box className={style.btnContainer}>
        <Grid container gap={2}>
          {data.month_list.map((value: ResMonth, innerKey: number) => (
            <Grid
              item
              xs={1.75}
              className={`${style.month} ${
                month === value.month && style.activeMonth
              }`}
              key={innerKey}
              onClick={() => setMonth(value.month)}
            >
              <Typography className={style.typoMonth}>
                {value.month}月
              </Typography>
              <Typography
                className={style.typoCount}
              >{`（${value.count} 件）`}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
      <TableContainer className={style.container}>
        <Table className={style.table}>
          <TableHead className={style.title}>
            <TableRow className={style.row}>
              <TableCell component="td" align="center" className={style.cell}>
                日付
              </TableCell>
              <TableCell component="td" align="center" className={style.cell}>
                申し込み件数
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={style.body}>
            {data.day_list.map((value: ResDay, innerKey: number) => (
              <Box key={innerKey} className={style.line}>
                <TableRow className={style.row}>
                  <TableCell align="center" className={style.cell}>
                    <Link
                      to={`/admin/applications?filter=${JSON.stringify({
                        application_date: filterDateTimeUtil(
                          new Date(`'${year}/${value.date}'`),
                        ),
                      })}`}
                      color="link"
                    >
                      {value.date}
                    </Link>
                  </TableCell>
                  <TableCell align="right" className={style.cell}>
                    {value.count}
                  </TableCell>
                </TableRow>
              </Box>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
