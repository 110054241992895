import { Typography } from '@mui/material'

import style from './style.module.css'
import { Props } from './type'

export const OrderAccuracy = ({ status }: Props) => {
  const component = () => {
    switch (status.id) {
      case 0:
        return (
          <Typography className={style.statusText} color="status.invalid">
            {status.name}
          </Typography>
        )
        break
      case 1:
        return (
          <Typography className={style.statusText} color="status.pending">
            {status.name}
          </Typography>
        )
        break
      case 2:
        return (
          <Typography className={style.statusText} color="status.valid">
            {status.name}
          </Typography>
        )
        break
    }
  }
  return <>{component()}</>
}
