import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

export const DateTimeUtil = (dateString: string) => {
  const parsedDate = new Date(dateString)

  const formattedDate = format(parsedDate, 'yyyy/MM/dd HH:mm', { locale: ja })

  return formattedDate
}

export const DateUtil = (dateString: string) => {
  const parsedDate = new Date(dateString)

  const formattedDate = format(parsedDate, 'yyyy/MM/dd', { locale: ja })

  return formattedDate
}

export const filterDateTimeUtil = (date: Date = new Date()) => {
  const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm", { locale: ja })

  return formattedDate
}

export const csvDateTimeUtil = (date: Date = new Date()) => {
  const csvDate = format(date, 'yyyyMMddHHmm', { locale: ja })
  return csvDate
}

export const InterviewDate = (date: string | null) => {
  if (!date) return

  const parsedDate = new Date(date)

  const formattedDate = format(parsedDate, 'yyyy-MM-dd HH:mm', { locale: ja })

  return formattedDate
}
