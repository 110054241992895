import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  FunctionField,
  Loading,
  Show,
  TextField,
  useGetOne,
  useGetRecordId,
  useRedirect,
} from 'react-admin'

import { Interview } from '../../../../provider/types/interview'
import { DateTimeUtil, DateUtil } from '../../../../Util/dateUtil'
import { FormatUtil } from '../../../../Util/formatUtil'
import { ApplicationStatusBar } from '../../../atom/ApplicationStatusBar'
import { ContentsTitle } from '../../../atom/ContentsTitle'
import { DetailLink } from '../../../atom/DetailLink'
import { MarkIcon } from '../../../atom/MarkIcon'
import { MuiButton } from '../../../atom/MuiButton'
import { MuiTopToolbar } from '../../../atom/MuiTopToolbar'
import { TopButton } from '../../../atom/TopButton'
import { MuiBreadCrumbs } from '../../../organism/MuiBreadCrumbs'
import { MuiTable } from '../../../organism/MuiTable'
import { MuiTooltip } from '../../../organism/MuiTooltip'
import { NotFound } from '../../../organism/NotFound'
import { TypeNote } from '../Edit/type'

import styles from './style.module.css'

export const ApplicationShow = () => {
  const recordId = useGetRecordId()
  const {
    data: applicationsData,
    isLoading,
    error,
  } = useGetOne('applications', { id: recordId })
  const redirect = useRedirect()

  if (isLoading) {
    return <Loading />
  }

  if (error || undefined === applicationsData) {
    return <NotFound />
  }

  return (
    <>
      <Show
        emptyWhileLoading
        className={styles.show}
        actions={
          <MuiTopToolbar>
            <MuiBreadCrumbs history={['申し込み一覧', '申し込みの詳細']} />
            <MuiButton
              text="修正"
              onClick={() => redirect('edit', 'applications', recordId)}
              icon={<EditIcon />}
            />
          </MuiTopToolbar>
        }
      >
        <MuiTopToolbar>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>
              案件ID：
              <FunctionField
                source="id"
                className={styles.responsiblePerson}
                render={(record: { id: number }) => (
                  <Link
                    href={`/admin/applications/${record.id}/show`}
                    color="link"
                  >
                    {record.id}
                  </Link>
                )}
              />
            </Typography>
            <Stack direction="row" alignItems="center">
              案件ステータス：
              <FunctionField
                source="status.id"
                className={styles.responsiblePerson}
                render={(record: { status: { id: number; name: string } }) => (
                  <ApplicationStatusBar status={record.status} />
                )}
              />
            </Stack>
          </Stack>
          <Typography>
            担当者：
            <FunctionField
              source="staff.id"
              label="担当者"
              className={styles.responsiblePerson}
              render={(record: { staff: { name: string } }) =>
                record.staff ? record.staff.name : '未設定'
              }
            />
          </Typography>
        </MuiTopToolbar>
        <ContentsTitle title="申し込み情報" />
        <MuiTable>
          <TableRow className={styles.tableRow}>
            <TableCell className={styles.tableRowTitle}>申し込み日時</TableCell>
            <TableCell className={styles.tableRowData}>
              <FunctionField
                label="申し込み日時"
                render={(record: { application_date: string }) =>
                  DateTimeUtil(record.application_date)
                }
              />
            </TableCell>
          </TableRow>
          <TableRow className={styles.tableRow}>
            <TableCell className={styles.tableRowTitle}>依頼者名</TableCell>
            <TableCell className={styles.tableRowData}>
              <FunctionField
                source="customer"
                label="依頼者名"
                render={(record: {
                  customer: { name: string; kana: string }
                }) =>
                  record.customer.kana.length > 0
                    ? `${record.customer.name} (${record.customer.kana})`
                    : record.customer.name
                }
              />
            </TableCell>
          </TableRow>
          <TableRow className={styles.tableRow}>
            <TableCell className={styles.tableRowTitle}>性別</TableCell>
            <TableCell className={styles.tableRowData}>
              <TextField label="性別" source="gender.name" />
            </TableCell>
          </TableRow>
          <TableRow className={styles.tableRow}>
            <TableCell className={styles.tableRowTitle}>年代</TableCell>
            <TableCell className={styles.tableRowData}>
              <TextField label="性別" source="age_group.name" />
            </TableCell>
          </TableRow>
          <TableRow className={styles.tableRow}>
            <TableCell className={styles.tableRowTitle}>都道府県</TableCell>
            <TableCell className={styles.tableRowData}>
              <TextField label="性別" source="prefecture.name" />
            </TableCell>
          </TableRow>
          <TableRow className={styles.tableRow}>
            <TableCell className={styles.tableRowTitle}>
              メールアドレス
            </TableCell>
            <TableCell className={styles.tableRowData}>
              <TextField label="メールアドレス" source="email" />
            </TableCell>
          </TableRow>
          <TableRow className={styles.tableRow}>
            <TableCell className={styles.tableRowTitle}>電話番号</TableCell>
            <TableCell className={styles.tableRowData}>
              <FunctionField
                label="電話番号"
                render={(record: { phone_number: string }) =>
                  FormatUtil.formatTelNumber(record.phone_number)
                }
              />
            </TableCell>
          </TableRow>
          <TableRow className={styles.tableRow}>
            <TableCell className={styles.tableRowTitle}>電話希望日時</TableCell>
            <TableCell className={styles.tableRowData}>
              <FunctionField
                label="電話希望日時"
                source="desired_call"
                render={(record: {
                  desired_call: { date: string; range_time: string }
                }) => (
                  <>
                    {record.desired_call.date
                      ? `${DateUtil(record.desired_call.date)}`
                      : ''}
                    {' '}
                    {record.desired_call.range_time
                      ? `${record.desired_call.range_time}`
                      : ''}
                  </>
                )}
              />
            </TableCell>
          </TableRow>
          <TableRow className={styles.tableRow}>
            <TableCell className={styles.tableRowTitle}>特記事項</TableCell>
            <TableCell className={`${styles.tableRowData} ${styles.newLine}`}>
              <TextField label="特記事項" source="note" />
            </TableCell>
          </TableRow>
        </MuiTable>
        <Box>
          <ContentsTitle title="ヒアリングシート" />
          <MuiTable>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>予算</TableCell>
              <TableCell className={styles.tableRowData}>
                <FunctionField
                  label="予算"
                  source="hearing.budget"
                  render={(record: { hearing: { budget: string } }) =>
                    record.hearing.budget ? `${record.hearing.budget}万円` : '-'
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>調査対象</TableCell>
              <TableCell className={styles.tableRowData}>
                <FunctionField
                  source="hearing.target"
                  label="調査対象"
                  render={(record: { hearing: { target: { name: string } } }) =>
                    `${
                      record.hearing.target.name
                        ? record.hearing.target.name
                        : '-'
                    }`
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>調査内容</TableCell>
              <TableCell className={styles.tableRowData}>
                <FunctionField
                  label="調査内容"
                  render={(record: {
                    hearing: { research_type: { name: string } }
                  }) => (
                    <span>
                      {record.hearing.research_type
                        ? record.hearing.research_type.name
                        : ''}
                    </span>
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>
                一番気になっている事
              </TableCell>
              <TableCell className={styles.tableRowData}>
                <TextField
                  className={styles.newLine}
                  label="一番気になっている事"
                  source="hearing.important_point.name"
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>依頼事項</TableCell>
              <TableCell className={styles.tableRowData}>
                <TextField
                  className={styles.newLine}
                  label="依頼事項"
                  source="hearing.detail"
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>
                面談のご希望内容
              </TableCell>
              <TableCell className={styles.tableRowData}>
                <TextField
                  className={styles.newLine}
                  label="面談のご希望内容"
                  source="hearing.interview_detail"
                />
              </TableCell>
            </TableRow>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.tableRowTitle}>契約確度</TableCell>
              <TableCell className={styles.tableRowData}>
                <FunctionField
                  label="契約確度"
                  render={(record: {
                    hearing: { order_accuracy: { name: string } }
                  }) => (
                    <span>
                      {record.hearing.order_accuracy
                        ? record.hearing.order_accuracy.name
                        : ''}
                    </span>
                  )}
                />
              </TableCell>
            </TableRow>
          </MuiTable>
        </Box>
        <Box className={styles.margin}>
          <ContentsTitle title="面談予定" />
          <Box className={styles.tableWrap}>
            <Table className={styles.table}>
              <TableHead>
                <TableRow className={styles.tableRow}>
                  <TableCell
                    className={`${styles.interviewRowTitle} ${styles.tableCenter}`}
                  >
                    面談ID
                  </TableCell>
                  <TableCell
                    className={`${styles.interviewRowTitle} ${styles.tableCenter}`}
                  >
                    日時
                  </TableCell>
                  <TableCell
                    className={`${styles.interviewRowTitle} ${styles.tableCenter}`}
                  >
                    探偵社
                  </TableCell>
                  <TableCell
                    className={`${styles.interviewRowTitle} ${styles.tableCenter}`}
                  >
                    場所
                  </TableCell>
                  <TableCell
                    className={`${styles.interviewRowTitle} ${styles.tableCenter}`}
                  >
                    面談メモ
                  </TableCell>
                  <TableCell
                    className={`${styles.interviewRowTitle} ${styles.tableCenter}`}
                  >
                    詳細
                  </TableCell>
                  <TableCell
                    className={`${styles.interviewRowTitle} ${styles.tableCenter}`}
                  >
                    編集
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={styles.tableBody}>
                {applicationsData.interviews &&
                  (applicationsData.interviews.length !== 0 ? (
                    applicationsData.interviews.map(
                      (item: Interview, index: number) => {
                        return (
                          <TableRow key={index} className={styles.tableRow}>
                            <TableCell
                              className={`${styles.tableRowData} ${styles.tableCenter}`}
                            >
                              <FunctionField
                                label={'面談ID'}
                                render={(record: {
                                  interviews: TypeNote[]
                                }) => (
                                  <DetailLink
                                    resource="interviews"
                                    id={record.interviews[index].id}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell
                              className={`${styles.tableRowData} ${styles.tableCenter}`}
                            >
                              <Typography
                                variant="overline"
                                className={styles.tdText}
                              >
                                {DateTimeUtil(item.interview_date)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={`${styles.tableRowData} ${styles.tableCenter}`}
                            >
                              <Typography
                                variant="overline"
                                className={styles.tdText}
                              >
                                {item.detective.company.name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={`${styles.tableRowData} ${styles.tableCenter}`}
                            >
                              <Typography
                                variant="overline"
                                className={styles.tdText}
                              >
                                {item.location}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={`${styles.tableRowData} ${styles.tableCenter}`}
                            >
                              <FunctionField
                                label="面談メモ"
                                render={(record: {
                                  interviews: Interview[]
                                }) => (
                                  <MuiTooltip
                                    id={record.interviews[index].id}
                                    title="面談メモ"
                                    text={
                                      record.interviews[index].internal_note
                                        ? (record.interviews[index]
                                            .internal_note as string)
                                        : ''
                                    }
                                    category="interview"
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell
                              className={`${styles.tableRowData} ${styles.tableCenter}`}
                            >
                              <FunctionField
                                label={'詳細'}
                                render={(record: {
                                  interviews: Interview[]
                                }) => (
                                  <Link
                                    className={styles.link}
                                    href={`/admin/interviews/${record.interviews[index].id}/show`}
                                  >
                                    {'詳細'}
                                  </Link>
                                )}
                              />
                            </TableCell>
                            <TableCell
                              className={`${styles.tableRowData} ${styles.tableCenter}`}
                            >
                              <FunctionField
                                label={'編集'}
                                render={(record: {
                                  interviews: Interview[]
                                }) => (
                                  <IconButton
                                    onClick={() =>
                                      redirect(
                                        'edit',
                                        'interviews',
                                        record.interviews[index].id,
                                      )
                                    }
                                  >
                                    <MarkIcon />
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      },
                    )
                  ) : (
                    <TableRow className={styles.tableRow}>
                      <TableCell
                        colSpan={7}
                        className={`${styles.tableRowData} ${styles.tableCenter}`}
                      >
                        <Typography
                          variant="overline"
                          className={styles.notData}
                        >
                          予定なし
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
        <Box className={styles.margin}>
          <Box className={styles.noteTitle}>
            <ContentsTitle title="申込メモ" />
            <Typography className={styles.comment}>
              <span className={styles.kome}>※</span>
              こちらの内容は共有時に含まれません。
            </Typography>
          </Box>
          <Box className={styles.note}>
            <TextField label="申込メモ" source="internal_note" />
          </Box>
        </Box>
      </Show>
      <TopButton />
    </>
  )
}
