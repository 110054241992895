import { Link } from '@mui/material'
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  usePermissions,
} from 'react-admin'

import { FormatUtil } from '../../../../Util/formatUtil'
import { CsvDownloadBtn } from '../../../atom/CsvDownloadBtn'
import { DetailLink } from '../../../atom/DetailLink'
import { InterviewStatus } from '../../../atom/InterviewStatus'
import { OrderAccuracy } from '../../../atom/OrderAccuracy'
import { InterviewsListFilters } from '../../../feature/interviews/InterviewsListFilters'
import { CustomToolbar } from '../../../organism/CustomToolbar'
import { DateTimeItems } from '../../../organism/DateTimeItems'
import { MuiTooltip } from '../../../organism/MuiTooltip'

import './global.css'
import style from './style.module.css'

export const InterviewList = () => {
  const { permissions } = usePermissions()

  return (
    <List actions={false}>
      <CustomToolbar history={['面談一覧']}>
        {permissions === 'admin' && <CsvDownloadBtn resources="interviews" />}
      </CustomToolbar>
      <InterviewsListFilters />
      <Datagrid bulkActionButtons={false} className="list">
        <FunctionField
          label={'面談ID'}
          source="id"
          render={(record: { id: number }) => (
            <DetailLink resource="interviews" id={record.id} />
          )}
        />
        <FunctionField
          label={'面談日時'}
          source="interview_date"
          render={(record: { interview_date: string }) => (
            <DateTimeItems dateTimes={record.interview_date} />
          )}
        />
        <TextField
          label={'依頼者'}
          source="application.customer.name"
          sortBy="applications.name"
        />
        <FunctionField
          label={'電話番号（依頼者）'}
          source="application.phone_number"
          sortBy="applications.phone_number"
          render={(record: { application: { phone_number: string } }) =>
            FormatUtil.formatTelNumber(record.application.phone_number)
          }
        />
        <FunctionField
          label={'確度'}
          source="application.hearing.order_accuracy"
          sortBy="hearings.order_accuracy"
          render={(record: {
            application: {
              hearing: { order_accuracy: { id: number; name: string } }
            }
          }) =>
            record.application.hearing.order_accuracy ? (
              <OrderAccuracy
                status={record.application.hearing.order_accuracy}
              />
            ) : (
              ''
            )
          }
        />
        <TextField
          label={'探偵社'}
          source="detective.company.name"
          sortBy="detectives.company_name"
        />
        <FunctionField
          label={'面談ステータス'}
          source="status"
          render={(record: {
            status: { id: number; name: string }
            interview_date: string
          }) => (
            <InterviewStatus
              status={record.status}
              interviewDate={record.interview_date}
            />
          )}
        />
        <FunctionField
          label={'メモ'}
          render={(record: { internal_note: string; id: number }) => (
            <MuiTooltip
              id={record.id}
              title="面談メモ"
              text={record.internal_note}
              category="interview"
            />
          )}
        />
        <FunctionField
          label={'詳細'}
          render={(record: { id: number }) => (
            <Link
              underline="none"
              className={style.showButton}
              href={`/admin/interviews/${record.id}/show`}
            >
              詳細
            </Link>
          )}
        />
      </Datagrid>
    </List>
  )
}
