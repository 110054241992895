import { Box, Typography } from '@mui/material'

import { Spinner } from '../../../atom/Spinner'

import { LoadingProps } from './type'

export const Loading = ({ text }: LoadingProps) => (
  <Box>
    <Spinner />
    <Typography align="center">{text}</Typography>
  </Box>
)
