import { Dialog, Box, Typography } from '@mui/material'

import style from './style.module.css'
import { Props } from './type'

export const MuiModal = ({ open, closeModal, children, title }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
    >
      <Box className={style.modalBox}>
        {title && <Typography className={style.title}>{title}</Typography>}
        <Box className={style.body}>{children}</Box>
      </Box>
    </Dialog>
  )
}
