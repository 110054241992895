import { TableCell, TableRow } from '@mui/material'
import { useEffect, useState } from 'react'
import { useGetIdentity, GetOneResult, Title } from 'react-admin'

import { apiProvider } from '../../../provider/apiProvider'
import { User, SendPassword } from '../../../provider/types/user'
import { ContentsTitle } from '../../atom/ContentsTitle'
import { MuiButton } from '../../atom/MuiButton'
import { CustomToolbar } from '../../organism/CustomToolbar'
import { MuiTable } from '../../organism/MuiTable'
import { PasswordChangeModal } from '../../organism/PasswordChangeModal'
import tableStyle from '../User/Show/style.module.css'

export const MyPageShow = () => {
  const { identity } = useGetIdentity()

  const [dataIdentity, setDataIdentity] = useState<User>()
  const [modalActiveFlg, setModalActiveFlg] = useState(false)
  const [dataSendSuccessFlg, setDataSendSuccessFlg] = useState(false)

  const [changePasswordState, setChangePasswordState] = useState<SendPassword>({
    login_id: '',
    current_password: '',
    new_password: '',
    new_password_confirmation: '',
  })

  /**
   * モーダルの開閉処理
   * @returns void
   */
  const changeModalActiveFlg = () => {
    setModalActiveFlg(!modalActiveFlg)

    if (dataSendSuccessFlg) {
      setDataSendSuccessFlg(false)
    }
  }

  /**
   * 新しいパスワード比較処理
   * @params {string} key オブジェクト名
   * @params {string} value 値
   */
  const updateChangePassword = (key: string, value: string) => {
    setChangePasswordState((prev) => ({ ...prev, [key]: value }))
  }

  /**
   * react-adminのuseGetIdentityの読み込みが完了時に実行
   */
  useEffect(() => {
    if (identity) {
      apiProvider.getUser().then((res: GetOneResult) => {
        setDataIdentity(res.data)
        updateChangePassword('login_id', String(res.data.login_id))
      })
    }
  }, [identity])

  return (
    <div>
      <Title title="マイページ" />
      <CustomToolbar history={['マイページ']}>
        <MuiButton onClick={changeModalActiveFlg} text="パスワードの変更" />
      </CustomToolbar>
      <ContentsTitle title="基本情報" />
      <MuiTable>
        <TableRow className={tableStyle.tableRow}>
          <TableCell className={tableStyle.tableRowTitle}>氏名</TableCell>
          <TableCell className={tableStyle.tableRowData}>
            {dataIdentity?.name}
          </TableCell>
        </TableRow>
        <TableRow className={tableStyle.tableRow}>
          <TableCell className={tableStyle.tableRowTitle}>ID</TableCell>
          <TableCell className={tableStyle.tableRowData}>
            {dataIdentity?.login_id}
          </TableCell>
        </TableRow>
        <TableRow className={tableStyle.tableRow}>
          <TableCell className={tableStyle.tableRowTitle}>権限</TableCell>
          <TableCell className={tableStyle.tableRowData}>
            {dataIdentity?.permission.name}
          </TableCell>
        </TableRow>
      </MuiTable>
      {modalActiveFlg && (
        <PasswordChangeModal
          modalActiveFlg={modalActiveFlg}
          changeModalActiveFlg={changeModalActiveFlg}
          dataSendSuccessFlg={dataSendSuccessFlg}
          setDataSendSuccessFlg={setDataSendSuccessFlg}
          changePasswordState={changePasswordState}
          updateChangePassword={updateChangePassword}
        />
      )}
    </div>
  )
}
