import { SvgIcon } from '@mui/material'

import style from './style.module.css'

export const MemoIcon = () => {
  return (
    <SvgIcon className={style.icon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32px"
        height="22px"
        viewBox="0 0 32 22"
        fill="none"
      >
        <path
          d="M7.875 5.8C7.875 5.29609 8.07254 4.81282 8.42417 4.4565C8.77581 4.10018 9.25272 3.9 9.75 3.9H22.25C22.7473 3.9 23.2242 4.10018 23.5758 4.4565C23.9275 4.81282 24.125 5.29609 24.125 5.8V13.4C24.125 13.9039 23.9275 14.3872 23.5758 14.7435C23.2242 15.0998 22.7473 15.3 22.25 15.3H14.4513L14.2087 15.4748L11.625 17.3267V15.3H9.75C9.25272 15.3 8.77581 15.0998 8.42417 14.7435C8.07254 14.3872 7.875 13.9039 7.875 13.4V5.8ZM9.75 2C8.75544 2 7.80161 2.40036 7.09835 3.11299C6.39509 3.82563 6 4.79218 6 5.8L6 13.4C6 14.4078 6.39509 15.3744 7.09835 16.087C7.80161 16.7996 8.75544 17.2 9.75 17.2V21L11.2288 19.9385L15.05 17.2H22.25C23.2446 17.2 24.1984 16.7996 24.9017 16.087C25.6049 15.3744 26 14.4078 26 13.4V5.8C26 4.79218 25.6049 3.82563 24.9017 3.11299C24.1984 2.40036 23.2446 2 22.25 2H9.75ZM19.75 10.8667C20.0815 10.8667 20.3995 10.7332 20.6339 10.4957C20.8683 10.2581 21 9.93594 21 9.6C21 9.26406 20.8683 8.94188 20.6339 8.70433C20.3995 8.46679 20.0815 8.33333 19.75 8.33333C19.4185 8.33333 19.1005 8.46679 18.8661 8.70433C18.6317 8.94188 18.5 9.26406 18.5 9.6C18.5 9.93594 18.6317 10.2581 18.8661 10.4957C19.1005 10.7332 19.4185 10.8667 19.75 10.8667ZM17.25 9.6C17.25 9.93594 17.1183 10.2581 16.8839 10.4957C16.6495 10.7332 16.3315 10.8667 16 10.8667C15.6685 10.8667 15.3505 10.7332 15.1161 10.4957C14.8817 10.2581 14.75 9.93594 14.75 9.6C14.75 9.26406 14.8817 8.94188 15.1161 8.70433C15.3505 8.46679 15.6685 8.33333 16 8.33333C16.3315 8.33333 16.6495 8.46679 16.8839 8.70433C17.1183 8.94188 17.25 9.26406 17.25 9.6ZM12.25 10.8667C12.5815 10.8667 12.8995 10.7332 13.1339 10.4957C13.3683 10.2581 13.5 9.93594 13.5 9.6C13.5 9.26406 13.3683 8.94188 13.1339 8.70433C12.8995 8.46679 12.5815 8.33333 12.25 8.33333C11.9185 8.33333 11.6005 8.46679 11.3661 8.70433C11.1317 8.94188 11 9.26406 11 9.6C11 9.93594 11.1317 10.2581 11.3661 10.4957C11.6005 10.7332 11.9185 10.8667 12.25 10.8667Z"
          fill="#457670"
        />
      </svg>
    </SvgIcon>
  )
}
