import { Button } from '@mui/material'

import style from './style.module.css'
import { Props } from './type'

export const MuiButton = ({ disabled, icon, text, onClick, color }: Props) => {
  return (
    <Button
      variant="contained"
      className={`${style.button} ${disabled ? style.disabled : ''}`}
      onClick={onClick}
      startIcon={icon}
      color={color === 'reverse' ? 'reverse' : undefined}
    >
      {text}{' '}
    </Button>
  )
}
