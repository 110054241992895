import { Box, TableCell, TableRow } from '@mui/material'
import { useState } from 'react'
import {
  EditButton,
  Loading,
  Show,
  TextField,
  useGetOne,
  useGetRecordId,
} from 'react-admin'

import { User } from '../../../../provider/types/user'
import { ContentsTitle } from '../../../atom/ContentsTitle'
import { MuiButton } from '../../../atom/MuiButton'
import { StatusText } from '../../../atom/StatusText'
import { CustomToolbar } from '../../../organism/CustomToolbar'
import { MuiTable } from '../../../organism/MuiTable'
import { NotFound } from '../../../organism/NotFound'
import { PasswordResetModal } from '../../../organism/PasswordResetModal'

import style from './style.module.css'

const ShowActions = ({ user }: { user: User }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <CustomToolbar history={['アカウント一覧', `${user.name}さんの詳細`]}>
        <MuiButton
          onClick={() => setOpen(true)}
          text="パスワードの再発行"
          color="reverse"
        />
        <EditButton variant="contained" label="編集" className={style.button} />
      </CustomToolbar>
      {open && (
        <PasswordResetModal
          open={open}
          closeModal={() => setOpen(false)}
          user={user}
        />
      )}
    </>
  )
}

export const UserShow = () => {
  const recordId = useGetRecordId()
  const {
    data: user,
    isLoading,
    error,
  } = useGetOne<User>('users', { id: Number(recordId) })

  if (isLoading) {
    return <Loading />
  }
  if (error || undefined === user) {
    return <NotFound />
  }
  return (
    <Show
      emptyWhileLoading
      className={style.show}
      actions={<ShowActions user={user} />}
    >
      <ContentsTitle title="基本情報" />
      <MuiTable>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>氏名</TableCell>
          <TableCell className={style.tableRowData}>
            <TextField label="名前" source="name" />
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>ステータス</TableCell>
          <TableCell className={style.tableRowData}>
            <StatusText status={user.status} />
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>ID</TableCell>
          <TableCell className={style.tableRowData}>
            <TextField label="ログインID" source="login_id" />
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>権限</TableCell>
          <TableCell className={style.tableRowData}>
            <TextField label="権限" source="permission.name" />
          </TableCell>
        </TableRow>
      </MuiTable>
      <Box>
        <ContentsTitle title="アカウントメモ" />
        <Box className={style.note}>
          <TextField label="アカウントメモ" source="internal_note" />
        </Box>
      </Box>
    </Show>
  )
}
