import { FormatUtil } from '../../Util/formatUtil'

export type Validate = {
  company?: {
    name: string
    kana: string
  }
  status?: {
    id: number
  }
  representative?: {
    name: string
    kana: string
  }
  phone_number?: string
  email?: string
  prefecture?: {
    id: number
  }
  address?: string
  internal_note?: string | null
}

export const ValidateForm = (values: Validate) => {
  const errors: Record<string | number | symbol, string> = {}

  if (!values.company?.name || values.company?.name.length > 100) {
    errors['company.name'] = 'error'
  }
  if (!values.representative?.name || values.representative?.name.length > 20) {
    errors['representative.name'] = 'error'
  }
  if (
    !values.phone_number ||
    !FormatUtil.isValidFormat(values.phone_number) ||
    !values.phone_number.match(/^[0-9]+$/)
  ) {
    errors.phone_number = 'error'
  }
  if (
    !values.email ||
    !values.email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
  ) {
    errors.email = 'error'
  }
  if (values.prefecture?.id == null) {
    errors['prefecture.id'] = 'error'
  }
  if (values.status?.id == null) {
    errors['status.id'] = 'error'
  }
  if (!values.address || values.address?.length > 1000) {
    errors.address = 'error'
  }
  if (!values.internal_note) {
    errors.internal_note = 'error'
  }

  if (Object.keys(errors).length > 0) {
    return errors
  } else {
    return {}
  }
}
