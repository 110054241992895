import { Tooltip, TextField, Box, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import { useRefresh } from 'react-admin'

import { apiProvider } from '../../../provider/apiProvider'
import { MemoIcon } from '../../atom/MemoIcon'
import { MemoPatch } from '../../atom/MemoPatch'
import { MuiButton } from '../../atom/MuiButton'
import { MuiModal } from '../MuiModal'

import style from './style.module.css'
import { Props } from './type'

export const MuiTooltip = ({ id, title, text, category }: Props) => {
  const [open, setOpen] = useState(false)
  const [memo, setMemo] = useState<string>(text)
  const [iconFlag, setIconFlag] = useState(text ? text.length > 0 : '')
  const [isUpdated, setIsUpdated] = useState(false)
  const refresh = useRefresh()

  const handleCloseModal = () => {
    setIsUpdated(false)
    setOpen(false)
  }

  const handleInterviewMemo = () => {
    apiProvider
      .updateInterviewMemo({
        id: id,
        data: {
          internal_note: memo,
        },
      })
      .then(() => {
        refresh()
        setIsUpdated(true)
        setIconFlag(memo.length > 0)
      })
      .catch(() => {
        refresh()
        setIconFlag(memo.length > 0)
        console.log('memo updates fail')
      })
  }

  const handleApplicationMemo = () => {
    apiProvider
      .updateApplicationMemo({
        id: id,
        data: {
          internal_note: memo,
        },
      })
      .then(() => {
        refresh()
        setIsUpdated(true)
        setIconFlag(memo.length > 0)
      })
      .catch(() => {
        refresh()
        setIconFlag(memo.length > 0)
        console.log('memo updates fail')
      })
  }

  return (
    <div>
      <Tooltip
        title={text}
        arrow
        classes={{
          tooltip: style.tooltip,
          arrow: style.arrow,
        }}
        onClick={() => setOpen(true)}
      >
        <IconButton>
          <MemoIcon />
          {iconFlag ? <MemoPatch /> : ''}
        </IconButton>
      </Tooltip>
      <MuiModal open={open} closeModal={handleCloseModal} title={title}>
        {!isUpdated ? (
          <Box className={style.box}>
            <TextField
              fullWidth
              multiline
              className={style.note}
              defaultValue={memo}
              onChange={(e) => {
                setMemo(e.target.value)
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                mt: 3,
              }}
            >
              <MuiButton
                onClick={handleCloseModal}
                text="閉じる"
                color="reverse"
              />
              <MuiButton
                onClick={
                  category === 'interview'
                    ? handleInterviewMemo
                    : handleApplicationMemo
                }
                text="更新する"
              />
            </Box>
          </Box>
        ) : (
          <Box className={style.box}>
            <Typography className={style.comment}>更新しました。</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                mt: 3,
              }}
            >
              <MuiButton
                onClick={handleCloseModal}
                color="reverse"
                text="閉じる"
              />
              <MuiButton onClick={() => setIsUpdated(false)} text="戻る" />
            </Box>
          </Box>
        )}
      </MuiModal>
    </div>
  )
}
