import { Typography, Box } from '@mui/material'
import { useState } from 'react'

import { MuiButton } from '../../atom/MuiButton'
import { MuiModal } from '../MuiModal'

export const ErrorModal = () => {
  const [open, setOpen] = useState(true)
  const closeModal = () => {
    setOpen(false)
  }
  return (
    <MuiModal open={open} closeModal={closeModal} title="パスワードの再発行">
      <Typography>もう一度更新を行なってください。</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          mt: 3,
        }}
      >
        <MuiButton onClick={closeModal} text="閉じる" color="reverse" />
        <MuiButton
          onClick={() => {
            window.location.reload()
          }}
          text="更新する"
        />
      </Box>
    </MuiModal>
  )
}
