import { Box, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { Title } from 'react-admin'

import { TabItem } from '../../atom/TabItem'
import { CustomToolbar } from '../../organism/CustomToolbar'

import { InterviewReport } from './InterviewReport'
import { ManagementReport } from './ManagementReport'

import './global.css'

export const Report = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box>
      <Title title="レポート" />
      <CustomToolbar history={['レポート']} />
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <Tab className="tab" label="運営レポート" />
        <Tab className="tab" label="面談レポート" />
      </Tabs>
      <TabItem value={value} index={0}>
        <ManagementReport />
      </TabItem>
      <TabItem value={value} index={1}>
        <InterviewReport />
      </TabItem>
    </Box>
  )
}
