import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditIcon from '@mui/icons-material/Edit'
import { TableCell, TableRow, Box, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { Show, useGetRecordId, useRedirect } from 'react-admin'

import { apiProvider } from '../../../../provider/apiProvider'
import { Detective, DefaultValue } from '../../../../provider/types/detective'
import { FormatUtil } from '../../../../Util/formatUtil'
import { ContentsTitle } from '../../../atom/ContentsTitle'
import { MuiButton } from '../../../atom/MuiButton'
import { StatusText } from '../../../atom/StatusText'
import { CustomToolbar } from '../../../organism/CustomToolbar'
import { MuiTable } from '../../../organism/MuiTable'

import style from './style.module.css'

export const DetectiveShow = () => {
  const recordId = useGetRecordId()
  const [isLoading, setIsLoading] = useState(false)
  const [detective, setDetective] = useState<Detective>(DefaultValue)
  const redirect = useRedirect()

  useEffect(() => {
    if (recordId) {
      apiProvider.getOne('detectives', { id: recordId }).then((response) => {
        console.log(response.data)
        setIsLoading(true)
        setDetective(response.data)
      })
    }
  }, [recordId])

  return isLoading ? (
    <Show
      className={style.show}
      actions={
        <CustomToolbar history={['探偵社一覧', detective.company.name]}>
          <MuiButton
            text="修正"
            onClick={() => redirect('edit', 'detectives', recordId)}
            icon={<EditIcon />}
          />
        </CustomToolbar>
      }
    >
      <ContentsTitle title="基本情報" />
      <MuiTable>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>探偵社名</TableCell>
          <TableCell className={style.tableRowData}>
            {`${detective.company.name}(${detective.company.kana})`}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(detective.company.name)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>ステータス</TableCell>
          <TableCell className={style.tableRowData}>
            <StatusText status={detective.status} />
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(detective.status.name)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>担当者</TableCell>
          <TableCell className={style.tableRowData}>
            {`${detective.representative.name}(${detective.representative.kana})`}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(detective.representative.name)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>電話番号</TableCell>
          <TableCell className={style.tableRowData}>
            {FormatUtil.formatTelNumber(detective.phone_number)}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(detective.phone_number)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>メールアドレス</TableCell>
          <TableCell className={style.tableRowData}>
            {detective.email}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(detective.email)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>都道府県</TableCell>
          <TableCell className={style.tableRowData}>
            {detective.prefecture.name}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(detective.prefecture.name)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={style.tableRow}>
          <TableCell className={style.tableRowTitle}>住所</TableCell>
          <TableCell className={style.tableRowData}>
            {detective.address}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(detective.address)
              }}
            >
              <ContentCopyIcon className={style.icon} />
            </IconButton>
          </TableCell>
        </TableRow>
      </MuiTable>
      <Box>
        <ContentsTitle title="探偵社メモ" />
        <Box className={style.note}>{detective.internal_note}</Box>
      </Box>
    </Show>
  ) : (
    <></>
  )
}
