import axios, { AxiosHeaders } from 'axios'
import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin'

const baseURL = process.env.REACT_APP_PUBLIC_API_ENDPOINT
// console.log(process.env)
// console.log(baseURL)

// https://marmelab.com/react-admin/DataProviderWriting.html
export const apiProvider: DataProvider = {
  // get a list of records based on sort, filter, and pagination
  getList: async (
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult> => {
    const limit = params.pagination.perPage
    const query = {
      sort: JSON.stringify(params.sort),
      limit: limit.toString(),
      page: params.pagination.page.toString(),
      filter: JSON.stringify(params.filter),
    }
    const searchParams = new URLSearchParams(query).toString()

    return await axios
      .get(`/v1/${resource}?${searchParams}`, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
  // get a single record by id
  getOne: async (
    resource: string,
    params: GetOneParams,
  ): Promise<GetOneResult> => {
    return await axios
      .get(`/v1/${resource}/${params.id}`, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
  // get a list of records based on an array of ids
  // TODO: bodyが空の仮置きになります
  getMany: async (resource: string): Promise<GetManyResult> => {
    return await axios
      .get(`/v1/${resource}/`, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
  // get the records referenced to another record, e.g. comments for a post
  getManyReference: async (
    resource: string,
  ): Promise<GetManyReferenceResult> => {
    return await axios
      .get(`/v1/${resource}/`, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
  // create a record
  create: async (
    resource: string,
    params: CreateParams,
  ): Promise<CreateResult> => {
    await axios.get('/sanctum/csrf-cookie', {
      baseURL: baseURL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withXSRFToken: true,
      withCredentials: true,
    })

    return await axios
      .post(`/v1/${resource}`, params.data, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
        withCredentials: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
  // update a record based on a patch
  update: async (
    resource: string,
    params: UpdateParams,
  ): Promise<UpdateResult> => {
    await axios.get('/sanctum/csrf-cookie', {
      baseURL: baseURL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withXSRFToken: true,
    })
    return await axios
      .put(`/v1/${resource}/${params.id}`, params.data, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
        withCredentials: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
  // update a list of records based on an array of ids and a common patch
  // TODO: bodyが空の仮置きになります
  updateMany: async (resource: string): Promise<UpdateManyResult> => {
    await axios.get('/sanctum/csrf-cookie', {
      baseURL: baseURL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withXSRFToken: true,
    })

    return await axios
      .put(
        `/v1/${resource}`,
        {},
        {
          baseURL: baseURL,
          headers: new AxiosHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }),
          withXSRFToken: true,
          withCredentials: true,
        },
      )
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
  // delete a record by id
  delete: async (
    resource: string,
    params: DeleteParams,
  ): Promise<DeleteResult> => {
    await axios.get('/sanctum/csrf-cookie', {
      baseURL: baseURL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withXSRFToken: true,
    })

    return await axios
      .delete(`/v1/${resource}/${params.id}`, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
        withCredentials: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
  // delete a list of records based on an array of ids
  // TODO: bodyが空の仮置きになります
  deleteMany: async (resource: string): Promise<DeleteManyResult> => {
    await axios.get('/sanctum/csrf-cookie', {
      baseURL: baseURL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withXSRFToken: true,
    })

    return await axios
      .delete(`/v1/${resource}`, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
        withCredentials: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
  // update user password by id
  updatePassword: async (params: UpdateParams): Promise<UpdateResult> => {
    await axios.get('/sanctum/csrf-cookie', {
      baseURL: baseURL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withXSRFToken: true,
    })

    return await axios
      .put(`/v1/users/${params.id}/reissue`, params.data, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
        withCredentials: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
      })
  },
  updateInterviewMemo: async (params: UpdateParams): Promise<UpdateResult> => {
    await axios.get('/sanctum/csrf-cookie', {
      baseURL: baseURL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withXSRFToken: true,
    })

    return await axios
      .put(`/v1/interviews/${params.id}/note`, params.data, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
        withCredentials: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
      })
  },
  updateApplicationMemo: async (
    params: UpdateParams,
  ): Promise<UpdateResult> => {
    await axios.get('/sanctum/csrf-cookie', {
      baseURL: baseURL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withXSRFToken: true,
    })

    return await axios
      .put(`/v1/applications/${params.id}/note`, params.data, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
        withCredentials: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
      })
  },
  updateDetectiveMemo: async (params: UpdateParams): Promise<UpdateResult> => {
    await axios.get('/sanctum/csrf-cookie', {
      baseURL: baseURL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withXSRFToken: true,
    })

    return await axios
      .post(`/v1/detectives/${params.id}/note`, params.data, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
        withCredentials: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
      })
  },
  getUser: async (): Promise<GetOneResult> => {
    await axios.get('/sanctum/csrf-cookie', {
      baseURL: baseURL,
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withXSRFToken: true,
    })

    return await axios
      .get(`/v1/profile`, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
        withCredentials: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
      })
  },
  getInterviewReports: async (params: GetOneParams): Promise<GetManyResult> => {
    return await axios
      .get(`/v1/reports/interview/${params.id}`, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
  getManagementReports: async (
    params: GetOneParams,
  ): Promise<GetManyResult> => {
    return await axios
      .get(`/v1/reports/operation/${params.meta.year}/${params.meta.month}`, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
  getChoicesList: async (url: string) => {
    return await axios
      .get(`/v1/${url}`, {
        baseURL: baseURL,
        headers: new AxiosHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }),
        withXSRFToken: true,
      })
      .then((res) => {
        return res.data.data
      })
      .catch((err) => {
        console.log(`Network error : ${err.message}`)
        throw err
      })
  },
}
