// 電話番号
import {
  PhoneNumberUtil,
  PhoneNumber,
  PhoneNumberFormat,
} from 'google-libphonenumber'

export class FormatUtil {
  public static formatTelNumber(value: string) {
    // 日本の国コード
    const region = 'JP'

    const util: PhoneNumberUtil = PhoneNumberUtil.getInstance()

    // 番号と地域を設定
    const number: PhoneNumber = util.parseAndKeepRawInput(value, region)

    // ハイフン付きの形式で返却
    return util.format(number, PhoneNumberFormat.NATIONAL)
  }

  public static isValidFormat(value: string) {
    const region = 'JP'

    const util: PhoneNumberUtil = PhoneNumberUtil.getInstance()
    const number: PhoneNumber = util.parseAndKeepRawInput(value, region)

    return util.isValidNumberForRegion(number, region)
  }
}
