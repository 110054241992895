import { Link } from '@mui/material'

import style from './style.module.css'
import { Props } from './type'

export const DetailLink = ({ resource, id }: Props) => {
  return (
    <Link className={style.color} href={`/admin/${resource}/${id}/show`}>
      {id}
    </Link>
  )
}
